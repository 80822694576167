import firebase from "firebase/app";

import { useMemo } from "react";

import createArtifactFiles from "astrid-firestore/src/api/artifacts/createArtifactFiles";
import sortArtifactFiles from "astrid-firestore/src/api/artifacts/sortArtifactFiles";
import useBucket from "astrid-hooks/src/useBucket";

import Form from "../../../../forms/components/Form/Form";

import sortArtifactFilesByName from "../../../utils/sortArtifactFilesByName";

export default function useArtifactManagerFiles({ form, artifact, files }) {
	const fileIds = form.watch("fileIds");

	const sortedFiles = useMemo(() => sortArtifactFiles(files, fileIds), [files, fileIds]);

	const { downloadFiles, deleteFiles } = useBucket("earselect-artifacts");

	const { swap, replace, append } = Form.useFieldArray({ name: "fileIds", control: form.control });

	const contentTypes = sortedFiles.map((file) => file.contentType);

	const resortedFiles = useMemo(() => sortArtifactFilesByName(sortedFiles), [sortedFiles]);

	const isFilesSorted = useMemo(
		() => JSON.stringify(sortedFiles.map(({ id }) => id)) === JSON.stringify(resortedFiles.map(({ id }) => id)),
		[resortedFiles, sortedFiles],
	);

	const onAddFiles = async (files) => {
		const documents = await createArtifactFiles(firebase, artifact, files);

		append(documents.map(({ id }) => id));
	};

	const onSortFiles = () => {
		replace(resortedFiles.map(({ id }) => id));
	};

	const onReorderFiles = (from, to) => {
		swap(from, to);
	};

	const onDownloadFiles = (files) => {
		return downloadFiles(files);
	};

	const onDeleteFiles = (filesToDelete) => {
		const filesToDeleteIds = filesToDelete.map(({ id }) => id);

		replace(fileIds.filter((id) => !filesToDeleteIds.includes(id)));

		// Delete uploaded files that are not yet saved
		deleteFiles(filesToDelete.filter((file) => !artifact.files.some((id) => id === file.id)));
	};

	return {
		sortedFiles,
		isFilesSorted,
		contentTypes,
		onAddFiles,
		onSortFiles,
		onReorderFiles,
		onDownloadFiles,
		onDeleteFiles,
	};
}
