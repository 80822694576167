import { useEffect, useMemo, useState } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";
import createTimelinePlayer from "astrid-helpers/src/audio/player/timelinePlayer";
import context from "astrid-helpers/src/audioContext";

export default function useArtifactAudioPlayerControls({ chunks }) {
	const [state, setState] = useState({
		action: "stop",
		position: 0,
	});

	const player = useMemo(() => {
		const player = createTimelinePlayer(context);

		for (const chunk of chunks) {
			player.addSound({
				bytes: chunk.bytes,
				position: chunk.start / 1000,
				duration: (chunk.stop - chunk.start) / 1000,
				getBuffer: chunk.getBuffer,
			});
		}

		return player;
	}, [chunks]);

	const play = () => {
		setState({
			action: "play",
			position: Timeline.getPosition(),
		});
	};

	const pause = () => {
		setState({
			action: "stop",
			position: Timeline.getPosition(),
		});
	};

	useEffect(() => {
		const { action, position } = state;

		if (action === "play") {
			const onTime = (time) => {
				Timeline.setPosition(position + time * 1000);
			};

			player.play(position / 1000);
			player.events.on("time", onTime);

			return () => {
				player.stop();
				player.events.off("time", onTime);
			};
		}
	}, [player, state]);

	return {
		play,
		pause,
		action: state.action,
	};
}
