import { useTranslation } from "react-i18next";

import formatBytes from "astrid-helpers/src/formatBytes";
import msToTime from "astrid-helpers/src/msToTime";

import { reorderColumn } from "../../ui/utils/columns";

export default function useArtifactFilesTableColumns({ contentTypes, onReorderFiles } = {}) {
	const { t } = useTranslation();

	const isAudio = contentTypes.includes("audio/wav");

	return [
		{
			id: "sequence",
			Header: t("sequence", "Sequence"),
			collapsing: true,
			Cell: ({ row, data }) => {
				const index = data.indexOf(row) + 1;

				return index.toString().padStart(3, "0");
			},
		},
		{
			id: "name",
			Header: t("name", "Name"),
		},
		{
			id: "contentType",
			Header: t("contentType", "Content-Type"),
		},
		{
			id: "duration",
			Header: t("duration", "Duration"),
			accessor: ({ duration }) => (duration ? msToTime(duration * 1000, false) : null),
			hidden: !isAudio,
		},
		{
			id: "channels",
			Header: t("channels", "Channels"),
			accessor: ({ format }) => (format ? (format.channels > 1 ? "Stereo" : "Mono") : null),
			hidden: !isAudio,
		},
		{
			id: "sampleRate",
			Header: t("sampleRate", "Sample Rate"),
			accessor: ({ format }) => (format ? `${format.sampleRate / 1000} kHz` : null),
			hidden: !isAudio,
		},
		{
			id: "bitDepth",
			Header: t("bitDepth", "Bit Depth"),
			accessor: ({ format }) => format?.bitDepth || null,
			hidden: !isAudio,
		},
		{
			id: "size",
			Header: t("size", "Size"),
			accessor: ({ size }) => formatBytes(size),
		},
		reorderColumn({ onReorder: onReorderFiles }),
	];
}
