import { Modal, Segment } from "semantic-ui-react";

import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import arrayChunk from "astrid-helpers/src/arrayChunk";

import Button from "../../../ui/components/Buttons/Button";
import FlexTable from "../../../ui/components/FlexTable/FlexTable";
import ErrorMessage from "../../../ui/components/Messages/ErrorMessage";
import ProgressLabel from "../../../ui/components/ProgressLabel/ProgressLabel";

export default function UploadFilesForm({ accept, onUploadFile, onSuccess, onClose }) {
	const { t } = useTranslation();
	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ accept });

	const [error, setError] = useState(null);
	const [fileStatus, setFileStatus] = useState({});
	const [fileProgress, setFileProgress] = useState({});

	const statusLabels = {
		pending: {
			children: t("pending", "Pending"),
			color: "grey",
		},
		uploading: {
			children: t("uploading", "Uploading"),
			color: "blue",
		},
		uploaded: {
			children: t("uploaded", "Uploaded"),
			color: "green",
		},
		error: {
			children: t("error", "Error"),
			color: "red",
		},
	};

	const columns = [
		{
			id: "name",
			flex: 1,
		},
		{
			id: "status",
			Cell: ({ row }) => {
				const status = fileStatus[row.original.name] || "pending";
				const progress = (fileProgress[row.original.name] || 0) * 100;

				return <ProgressLabel active={status === "uploading"} {...statusLabels[status]} percent={progress} />;
			},
		},
	];

	const uploadFile = async (file) => {
		try {
			setFileStatus((prev) => ({
				...prev,
				[file.name]: "uploading",
			}));

			await onUploadFile(file, (progress) => {
				setFileProgress((prev) => ({
					...prev,
					[file.name]: progress,
				}));
			});

			setFileStatus((prev) => ({
				...prev,
				[file.name]: "uploaded",
			}));
		} catch (error) {
			setFileStatus((prev) => ({
				...prev,
				[file.name]: "error",
			}));

			throw error;
		}
	};

	const onSubmit = async () => {
		try {
			for (const chunk of arrayChunk(acceptedFiles, 5)) {
				await Promise.all(chunk.map(uploadFile));
			}

			onSuccess();
			onClose();
		} catch (error) {
			setError(error);
		}
	};

	const isUploading =
		Object.keys(fileStatus).length > 0 && Object.values(fileStatus).some((status) => status === "uploading");

	return (
		<Modal open size="tiny" onClose={onClose} closeOnDimmerClick>
			<Modal.Header>{t("uploadFiles", "Upload files")}</Modal.Header>

			<Modal.Content scrolling>
				{acceptedFiles.length > 0 ? (
					<FlexTable data={acceptedFiles} columns={columns} />
				) : (
					<Segment placeholder {...getRootProps({ className: "dropzone" })}>
						<input {...getInputProps()} />
						<Button>{t("chooseFiles")}</Button>
					</Segment>
				)}

				{error && <ErrorMessage error={error} />}
			</Modal.Content>

			<Modal.Actions>
				<Button disabled={isUploading} onClick={onClose}>
					{t("cancel", "Cancel")}
				</Button>

				<Button
					primary
					loading={isUploading}
					disabled={acceptedFiles.length === 0 || isUploading || error}
					onClick={onSubmit}
				>
					{t("upload", "Upload")}
				</Button>
			</Modal.Actions>
		</Modal>
	);
}
