import { Icon, Popup } from "semantic-ui-react";

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";
import money from "astrid-helpers/src/money";

import useCostTypes from "../../../hooks/useCostTypes";
import { costPriceAccessor, createdAccessor, totalAccessor } from "../../../utils/costAccessors";

import FinalizedCostActions from "../components/FinalizedCostActions";

export default function useFinalizedCostsTableColumns() {
	const { t } = useTranslation();

	const costTypes = useCostTypes();

	return useMemo(
		() => [
			{
				id: "secondParty.name",
				filter: "text",
				Header: t("secondParty", "Second party"),
			},
			{
				id: "price.type",
				filter: "select",
				Header: t("type", "Type"),
				accessor: ({ price }) => costTypes[price.type],
			},
			{
				id: "price.price",
				filter: "text",
				Header: t("price", "Price"),
				accessor: (cost) =>
					cost?.price?.price === null ? t("hiddenPrice", "Hidden price") : costPriceAccessor(cost),
			},
			{
				id: "created",
				Header: t("created", "Created"),
				accessor: createdAccessor,
			},
			{
				id: "orderId",
				Header: t("order", "Order"),
				Cell: ({ row, value }) => {
					if (row.original.firstParty.type !== organizationTypes.PRODUCER) {
						return value;
					}
					return (
						<Link
							to={`/${row.original.firstParty.type}/${row.original.firstParty.id}/finance/purchases/${row.original.orderId}`}
						>
							{value}
						</Link>
					);
				},
			},
			{
				id: "total",
				Header: t("sum", "Sum"),
				textAlign: "right",
				accessor: totalAccessor,
				Cell: ({ row, value }) => (
					<>
						{money(value, row.original.price.currency)}

						{row.original.employee && (
							<Popup
								trigger={<Icon name="stopwatch" />}
								inverted
								size="mini"
								content={
									row.original.amountProduced
										? t("producedTime", {
												mins: Math.floor(row.original.amountProduced),
										  })
										: t("costAgreementEmployed")
								}
							/>
						)}
					</>
				),
			},
			{
				id: "actions",
				textAlign: "right",
				collapsing: true,
				Cell: FinalizedCostActions,
			},
		],
		[costTypes, t],
	);
}
