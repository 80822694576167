import { Input } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import DropdownMenu from "../../../ui/components/DropdownMenu/DropdownMenu";
import DropdownMenuItem from "../../../ui/components/DropdownMenu/DropdownMenuItem";
import InstantSearch from "../../../ui/components/InstantSearch/InstantSearch";
import InstantSearchBox from "../../../ui/components/InstantSearch/InstantSearchBox";
import InstantSearchConfigure from "../../../ui/components/InstantSearch/InstantSearchConfigure";
import InstantSearchIndex from "../../../ui/components/InstantSearch/InstantSearchIndex";

import { getOrganizationPath } from "../../../routing/utils/getOrganizationPath";

import QuickSearchItemArticle from "./components/QuickSearchItemArticle";
import QuickSearchItemProduction from "./components/QuickSearchItemProduction";
import QuickSearchItemWork from "./components/QuickSearchItemWork";
import QuickSearchItems from "./components/QuickSearchItems";

export default function QuickSearchBeta({ organization = {} }) {
	const { t } = useTranslation();

	const { id, type } = organization;

	return (
		<InstantSearch indexName="articles" configure={{ hitsPerPage: 5 }}>
			<InstantSearchBox>
				{({ searchQuery, setSearchQuery }) => (
					<DropdownMenu
						open={!!searchQuery}
						direction="right"
						closeOnBlur
						onClose={() => setSearchQuery("")}
						menuStyle={{ minHeight: 45 }}
						trigger={
							<Input
								transparent
								focus
								value={searchQuery}
								placeholder={t("search", "Search") + "..."}
								onChange={(e, { value }) => setSearchQuery(value)}
							/>
						}
					>
						<DropdownMenuItem disabled style={{ position: "absolute" }}>
							{t("noResults", "No results")}
						</DropdownMenuItem>

						{searchQuery && [organizationTypes.ADMIN, organizationTypes.PUBLISHER].includes(type) && (
							<InstantSearchIndex indexName="articles">
								<InstantSearchConfigure
									filters={type !== organizationTypes.ADMIN ? `${type}.id:${id}` : ""}
								/>

								<QuickSearchItems
									header={t("articles", "Articles")}
									getItemPath={(item) => getOrganizationPath(`/articles/${item.id}`)}
									getMorePath={() =>
										getOrganizationPath(`/articles?articles%5Bquery%5D=${searchQuery}`)
									}
								>
									<QuickSearchItemArticle showPublisher={type !== organizationTypes.PUBLISHER} />
								</QuickSearchItems>
							</InstantSearchIndex>
						)}

						{searchQuery && [organizationTypes.ADMIN, organizationTypes.PUBLISHER].includes(type) && (
							<InstantSearchIndex indexName="works">
								<InstantSearchConfigure
									filters={type !== organizationTypes.ADMIN ? `${type}.id:${id}` : ""}
								/>

								<QuickSearchItems
									header={t("works", "Works")}
									getItemPath={(item) => getOrganizationPath(`/works/${item.id}`)}
									getMorePath={() => getOrganizationPath(`/works?works%5Bquery%5D=${searchQuery}`)}
								>
									<QuickSearchItemWork showPublisher={type !== organizationTypes.PUBLISHER} />
								</QuickSearchItems>
							</InstantSearchIndex>
						)}

						{searchQuery && type !== organizationTypes.NARRATOR && (
							<InstantSearchIndex indexName="productions">
								<InstantSearchConfigure
									filters={type !== organizationTypes.ADMIN ? `${type}:${id}` : ""}
									hitsPerPage={10}
									restrictSearchableAttributes={["title", "series", "isbn", "ebookISBN", "partISBN"]}
								/>

								<QuickSearchItems
									header={t("productions", "Productions")}
									getItemPath={(item) => `/production/${item.id}`}
									getMorePath={() =>
										getOrganizationPath(`/list-productions?productions%5Bquery%5D=${searchQuery}`)
									}
								>
									<QuickSearchItemProduction
										showProducer={type !== organizationTypes.PRODUCER}
										showPublisher={type !== organizationTypes.PUBLISHER}
									/>
								</QuickSearchItems>
							</InstantSearchIndex>
						)}

						{searchQuery && type === organizationTypes.NARRATOR && (
							<InstantSearchIndex indexName="productions">
								<InstantSearchConfigure
									filters={`readers:${id}`}
									hitsPerPage={10}
									restrictSearchableAttributes={["title", "series", "isbn", "ebookISBN", "partISBN"]}
								/>

								<QuickSearchItems
									header={t("productions", "Productions")}
									getItemPath={(item) => `/production/${item.id}`}
								>
									<QuickSearchItemProduction showProducer showPublisher />
								</QuickSearchItems>
							</InstantSearchIndex>
						)}
					</DropdownMenu>
				)}
			</InstantSearchBox>
		</InstantSearch>
	);
}
