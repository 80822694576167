import createDocumentData from "../../utils/createDocumentData";
import runBatch from "../../utils/runBatch";

import { ArtifactFile } from "./types/ArtifactFile";

export default function createArtifactFiles(firebase, artifact, files, batch) {
	return runBatch(
		firebase,
		(batch) => {
			const documents = [];

			for (const data of files) {
				const file = ArtifactFile.parse(createDocumentData(firebase, `artifacts/${artifact.id}/files`, data));

				documents.push(file);

				batch.set(file.ref, file);
			}

			return documents;
		},
		batch,
	);
}
