import { useTranslation } from "react-i18next";

import VerticalMenu from "../../../ui/components/VerticalMenu/VerticalMenu";
import PageView from "../../../ui/views/PageView/PageView";

import useOrganizationFinanceMenuOptions from "./hooks/useOrganizationFinanceMenuOptions";

export default function OrganizationFinanceView({ organization, children }) {
	const { t } = useTranslation();

	const options = useOrganizationFinanceMenuOptions({ organization });

	return (
		<PageView header={t("finance", "Finance")} sidebar={<VerticalMenu options={options} />} maxWidth={1800}>
			{children}
		</PageView>
	);
}
