import React from "react";
import { useTranslation } from "react-i18next";

import articleTypes from "astrid-firestore/src/api/articles/constants/articleTypes";
import secondsToDuration from "astrid-firestore/src/api/articles/utils/secondsToDuration";

import { timestampColumn } from "../../ui/utils/columns";

import ArtifactStatusLabels from "../components/ArtifactStatus/ArtifactStatusLabels";

export default function useArtifactsTableColumns({ article } = {}) {
	const { t } = useTranslation();

	return [
		{
			id: "status",
			Header: t("status", "Status"),
			collapsing: true,
			Cell: ({ row }) => {
				return <ArtifactStatusLabels artifact={row} article={article} />;
			},
		},
		{
			id: "name",
			Header: t("name", "Name"),
		},
		{
			id: "contentType",
			Header: t("contentType", "Content-Type"),
		},
		{
			id: "duration",
			Header: t("duration", "Duration"),
			hidden: article?.type === articleTypes.EBOOK,
			accessor: ({ duration }) =>
				typeof duration === "number" ? secondsToDuration(duration, { padHours: 2 }) : undefined,
		},
		{
			id: "createdBy",
			Header: t("producedBy", "Produced by"),
			accessor: ({ producer }) => producer?.name,
		},
		timestampColumn({ id: "created", Header: t("created", "Created") }),
	];
}
