import get from "lodash/get";

import { Button, Comment, Form, Icon, Label, Segment } from "semantic-ui-react";

import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { formatPhoneNumberIntl } from "react-phone-number-input";

import { base, db, firebase, getProductionPermissions, notifyUsers } from "astrid-firebase";
import addEvent from "astrid-firebase/src/utils";
import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";
import { getCollectionData } from "astrid-firestore/src/helpers";
import UploadButton from "astrid-web/src/components/UploadButton";
import { withStore } from "astrid-web/src/helpers/context";

import hasLanguageOptions from "../../../features/language/hasLanguageOptions";

import ProductionSingleComment from "./ProductionSingleComment";

class ProductionComments extends Component {
	state = {
		loading: true,
		comments: [],
		posting: false,
		uploadText: null,
		uploadedFile: null,
		uploadedFileName: null,
		recipient: {},
		series: undefined,
		seriesProductions: undefined,
		seriesComments: [],
		editRecipient: null,
		newComment: false,
	};

	lastRender = 0;

	componentDidMount() {
		this.recipient = this.getRecipientQuery();

		// get user and org data (needed for single comment tab view on mobile)
		this.props.store.getUsers();
		this.props.store.getOrganizations();

		if (this.recipient) {
			base.bindCollection("productions/" + this.props.productionId + "/comments", {
				context: this,
				state: "comments",
				withIds: true,
				query: (ref) => ref.where(this.recipient, "==", true),
				then() {
					this.setState({ loading: false });

					const qs = new URLSearchParams(window.location.search);
					const target = qs.get("comment");

					if (target) {
						this.scrollTo("#comment-" + target);
					}
				},
				onFailure(err) {
					console.log("comments err", err);
				},
			});
		}

		const { store, production, subcontractor } = this.props;
		production.subcontractor = subcontractor;
		const productionPermissions = getProductionPermissions(store, production);
		const { isPublisher, isReader, isProducer, isSubcontractor } = productionPermissions;

		// set default comment recipients
		let recipient;
		if (isProducer) {
			recipient = {
				prodAdmin: true,
				prodStaff: true,
			};
		}
		if (isReader) {
			recipient = {
				prodAdmin: true,
				prodStaff: true,
				reader: true,
			};
		}
		if (isSubcontractor) {
			recipient = {
				prodAdmin: true,
				subcontractor: true,
			};
		}
		if (isPublisher) {
			recipient = {
				prodAdmin: true,
				publisher: true,
			};
		}
		if (this.props.internalPublisher) {
			recipient = {
				internalPublisher: true,
			};
		}

		if (recipient) this.setState({ recipient });

		this.interval = setInterval(this.tick, 30000);
	}

	tick = () => {
		const now = +new Date();
		if (now - this.lastRender > 30000) {
			// console.log("30s tick");
			this.setState({ triggeredUpdate: now });
		}
	};

	scrollTo = (selector) => {
		const parent = document.querySelector(".sticky-secondary .scroll-fade");
		const target = selector && document.querySelector(selector);

		const offset = parent ? parent.getBoundingClientRect().y : 0;
		const y = target ? target.getBoundingClientRect().y - offset - 50 : 100000;

		(parent || window).scrollTo({
			top: y,
			left: 0,
			behavior: "smooth",
		});
	};

	goToLatest = (latestComment) => {
		if (latestComment) {
			const selector = "#comment-" + latestComment;
			const target = selector && document.querySelector(selector);
			target.scrollIntoView();
		}
	};

	getSeriesComments = async () => {
		if (this.state.seriesProductions) {
			return;
		}

		const {
			productionId,
			production: { publisher, series },
		} = this.props;

		const productions = await getCollectionData(
			db
				.collection("productions")
				.where("publisher", "==", publisher)
				.where("series", "==", series)
				.where("hasSeriesComment", "==", true),
		);

		const seriesProductions = productions?.filter((prod) => prod.id !== productionId);

		if (seriesProductions.length === 0) {
			return;
		}

		this.setState({
			seriesProductions: seriesProductions.reduce((acc, prod) => ({ ...acc, [prod.id]: prod }), {}),
			seriesComments: [],
		});

		seriesProductions.forEach((prod) => {
			base.get("productions/" + prod.id + "/comments", {
				context: this,
				withIds: true,
				query: (ref) => ref.where("seriesComment", "==", true).where(this.recipient, "==", true),
			})
				.then((data) => {
					this.setState({
						seriesComments: [
							...this.state.seriesComments,
							...data
								.filter((comment) =>
									this.props.internalPublisher
										? comment.recipient.internalPublisher === this.props.internalPublisher
										: !comment.recipient.internalPublisher,
								)
								.map((comment) => ({ ...comment, productionId: prod.id })),
						],
					});
				})
				.catch((err) => {
					console.log("Series comment err", err);
				});
		});
	};

	componentDidUpdate() {
		const { production } = this.props;

		if (production.series && production.series !== this.state.series && production.publisher) {
			// series
			this.setState({ series: production.series, seriesComments: [] });
			this.getSeriesComments();
		}
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	getRecipientQuery = () => {
		const { store, production, internalPublisher, subcontractor } = this.props;
		production.subcontractor = subcontractor;

		const productionPermissions = getProductionPermissions(store, production);
		const { isReader, isProducer, isPublisher, isSubcontractor } = productionPermissions;

		if (isSubcontractor) return "recipient.subcontractor";
		else if (isProducer?.includes?.("producerAdmin") || isProducer?.includes?.("producerStaff"))
			return "recipient.prodAdmin";
		else if (isReader) return "recipient.reader";
		else if (internalPublisher) return "recipient.internalPublisher";
		else if (isPublisher) return "recipient.publisher";
		else if (isProducer) return "recipient.prodStaff";
	};

	uploadFile = (e) => {
		const storageRef = firebase
			.storage()
			.refFromURL(window.ES.stage ? "gs://stage-earselect-secret" : "gs://earselect-secret");

		// file from input
		const file = e.target.files[0];
		if (!file) return;

		// start upload
		const timestamp = +new Date(); // create folder to allow file names with identical names
		const path = "comments/" + this.props.productionId + "/" + timestamp + "/" + file.name;
		const uploadTask = storageRef.child(path).put(file);

		// upload status events
		uploadTask.on(
			firebase.storage.TaskEvent.STATE_CHANGED,
			(snapshot) => {
				// progress
				const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
				this.setState({ uploadText: progress + "%" });
			},
			(error) => {
				// error
				console.log(error);
			},
			() => {
				// success, store in db
				uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
					this.setState({ uploadText: null, uploadedFile: downloadURL, uploadedFileName: file.name });
				});
			},
		);
	};

	submitComment = (e, reply, recipient, uploadedFile, uploadedFileName, seriesComment) => {
		const textField = e.target.querySelector("[name=commentText]");
		this.setState({ posting: true });

		const data = {
			text: textField.value,
			user: this.props.store.state.user.uid,
			recipient: recipient || this.state.recipient,
			time: firebase.firestore.FieldValue.serverTimestamp(),
		};

		// store currently selected readers, remove denied
		if (data.recipient.reader && this.props.production.reader) {
			data.readerUsers = this.props.production.reader.filter(
				(reader) => get(this, "props.production.readerStatus." + reader + ".status") !== "denied",
			);
		}

		if (this.state.uploadedFile) {
			// store file url
			data.file = this.state.uploadedFile;
			data.fileName = this.state.uploadedFileName;
		}

		if (this.state.seriesComment) {
			data.seriesComment = true;
		}

		// reply submit is called from single comment component, get some info form func args
		if (reply && typeof reply === "string") {
			data.reply = reply;
			if (uploadedFile) {
				data.file = uploadedFile;
				data.fileName = uploadedFileName;
			}

			if (seriesComment) data.seriesComment = true;
		}

		base.addToCollection("productions/" + this.props.productionId + "/comments", data)
			.then((ref) => {
				textField.value = "";

				// update production
				const prodData = {};

				// if producer posted comment to publisher, store that in separate column to sort by
				if (data.recipient.publisher || data.recipient.internalPublisher) {
					prodData.updatedToPublisher = firebase.firestore.FieldValue.serverTimestamp();
				}

				// if comment for all books in series, set flag on production for db query purposes
				if (data.seriesComment) {
					prodData.hasSeriesComment = true;
				}

				if (!data.recipient.internalPublisher) {
					// set update date on production
					prodData.updated = firebase.firestore.FieldValue.serverTimestamp();

					// unsnooze
					prodData.statusPlanningSnooze = firebase.firestore.FieldValue.delete();

					// flag production if last comment is from publisher
					if (this.props.isPublisher && data.recipient.prodAdmin)
						prodData.lastCommentPublisher = firebase.firestore.FieldValue.serverTimestamp();

					// unflag production if reply to publisher
					if (data.reply && !this.props.isPublisher && data.recipient.publisher)
						prodData.lastCommentPublisher = false;
				}

				// update timestamp and more on production
				base.updateDoc("productions/" + this.props.productionId, prodData).then(() => {
					// production is updated
					this.setState({
						posting: false,
						uploadedFile: null,
						uploadedFileName: null,
					});
				});

				const { email, firstName, lastName } = this.props.store.state.profile;

				// store event in subcollection for blaming
				addEvent({
					productionId: this.props.productionId,
					user: this.props.store.state.user.uid,
					email: email,
					data: { addComment: { ...data, user: firstName + " " + lastName } },
				});

				// send notifications
				this.notifyAll({ id: ref.id, ...data });
			})
			.catch((error) => {
				console.log("Comment add error", error);
				this.setState({ posting: false });
			});
	};

	notifyAll = (newComment) => {
		const { production, productionId, store, t } = this.props;
		const {
			users: userMap,
			user: { uid },
			profile,
		} = store.state;
		const { comments } = this.state;

		const sender = profile.firstName + " " + profile.lastName;

		const commentThreadUsers = comments
			.filter(
				(comment) => (comment.reply && comment.reply === newComment.reply) || comment.id === newComment.reply,
			)
			.map((comment) => comment.user);

		const toBeNotified = Object.entries(newComment.recipient)
			?.reduce((acc, [recipient, checked]) => {
				if (checked) {
					switch (recipient) {
						case "prodAdmin":
							return acc.concat(production.manager || []);
						case "prodStaff": // workgroup
							return acc
								.concat(production.editor || [])
								.concat(production.proofer || [])
								.concat(production.bookedRecorders || []);
						case "publisher":
						case "internalPublisher":
							if (
								production?.publisherManagerIds?.filter(
									(publisherManagerId) =>
										store.state.users?.[publisherManagerId]?.organizations?.[production.publisher],
								)?.length
							) {
								return acc.concat(production.publisherManagerIds || []);
							} else {
								return acc.concat(
									Object.entries(userMap || {}).map(([userId, user]) => {
										return user?.permissions?.publisher?.[production.publisher] &&
											hasLanguageOptions({
												user,
												orgId: production.publisher,
												orgType: organizationTypes.PUBLISHER,
												languageToMatch: production.language,
											})
											? userId
											: undefined;
									}),
								);
							}
						case "reader":
							return acc.concat(production.reader || []);
						case "subcontractor":
							return acc.concat(production.subcontractor || []);
						default:
							return acc;
					}
				}
				return acc;
			}, [])
			.concat(commentThreadUsers)
			.filter((userId) => {
				// not to self.
				return userId && userId !== uid;
			});

		const shortenedMessage = newComment.text.slice(0, 100);

		const subject = t("newCommentSubject", { title: production.title });
		const message = t("newCommentMessage", {
			title: production.title,
			sender: sender,
			message: newComment.text.length > 100 ? shortenedMessage + "..." : shortenedMessage,
			url: window.ES.stage
				? `https://stage.astrid.fm/production/${productionId}/discussion`
				: `https://astrid.fm/production/${productionId}/discussion`,
		});
		// send notifications
		notifyUsers(toBeNotified, userMap, subject, message);
	};

	deleteComment = (id) => {
		const { t } = this.props;
		if (window.confirm(t("areYouSureAboutDelete"))) {
			const comments = this.state.comments;
			const commentData = comments.find((com) => com.id === id);
			let { store, production } = this.props;
			const { users: userMap } = store.state;

			// figure out if admin alerts need to be updated
			const adminAttentionRemaining = comments.find((com) => com.id !== id && com.adminAttention);
			const lastComment = comments.filter((com) => com.id !== id).sort((a, b) => (a.time < b.time ? 1 : -1))[0];
			const lastCommentFromPublisher =
				lastComment?.recipient?.publisher &&
				userMap[lastComment.user]?.permissions?.publisher?.[production.publisher];
			const updateAdminAlerts =
				!!lastCommentFromPublisher !== !!production.lastCommentPublisher ||
				!!adminAttentionRemaining !== !!production.adminAttention;

			const { email, firstName, lastName } = this.props.store.state.profile;

			// store event in subcollection for blaming
			addEvent({
				productionId: this.props.productionId,
				user: this.props.store.state.user.uid,
				email: email,
				data: { deleteComment: { ...commentData, user: firstName + " " + lastName } },
			}).then(() => {
				if (updateAdminAlerts) {
					// update production with admin alerts
					base.updateDoc("productions/" + this.props.productionId, {
						lastCommentPublisher: lastCommentFromPublisher ? lastComment.time : false,
						adminAttention: adminAttentionRemaining ? adminAttentionRemaining.time : false,
					});
				}

				// actually delete the comment
				base.removeDoc("productions/" + this.props.productionId + "/comments/" + id)
					.then(() => {
						console.log("Comment deleted");
					})
					.catch((error) => {
						console.log("Comment delete error", error);
					});
			});
		}
	};

	thumbComment = (id, thumbs, adminAlertOff) => {
		const newThumbs = thumbs || [];
		const thumbIndex = newThumbs.indexOf(this.props.store.state.user.uid);

		if (thumbIndex >= 0) {
			// unthumb
			newThumbs.splice(thumbIndex, 1);
		} else {
			// thumb
			newThumbs.push(this.props.store.state.user.uid);
		}

		base.updateDoc("productions/" + this.props.productionId + "/comments/" + id, { thumbs: newThumbs })
			.then((data) => {
				// console.log("Updated thumb status");
				if (adminAlertOff) {
					base.updateDoc("productions/" + this.props.productionId, { lastCommentPublisher: false });
				}
			})
			.catch((err) => {
				console.error("Thumb error", err);
			});
	};

	setAdminAttention = (id, state) => {
		base.updateDoc("productions/" + this.props.productionId + "/comments/" + id, { adminAttention: !!state })
			.then((data) => {
				// check if there are attention comments after update.
				const prodAttentionNeeded = !!this.state.comments.find((comment) => comment.adminAttention);

				// set flag on production as well, if necessary
				if (!!this.props.production.adminAttention !== prodAttentionNeeded) {
					const val = prodAttentionNeeded ? firebase.firestore.FieldValue.serverTimestamp() : false;
					base.updateDoc("productions/" + this.props.productionId, { adminAttention: val });
				}
			})
			.catch((err) => {
				console.error("Attention error", err);
			});
	};

	setSeriesComment = (id, state) => {
		base.updateDoc("productions/" + this.props.productionId + "/comments/" + id, { seriesComment: !!state })
			.then((data) => {
				if (state) {
					// set flag on production for querying
					base.updateDoc("productions/" + this.props.productionId, { hasSeriesComment: true });
				}
			})
			.catch((err) => {
				console.error("Series comment error", err);
			});
	};

	setCommentRecipient = async (id, recipient, state) => {
		// set recipient
		const newData = {
			["recipient." + recipient]: !!state,
		};

		if (recipient === "reader" && state === true && this.props.production.reader) {
			newData.readerUsers = this.props.production.reader.filter(
				(reader) => get(this, "props.production.readerStatus." + reader + ".status") !== "denied",
			);
		}

		// update main post instantly
		base.updateDoc("productions/" + this.props.productionId + "/comments/" + id, newData).catch((err) => {
			console.error("Series recpient error", err);
		});

		// check for replies to batch
		const replies = await db
			.collection("productions/" + this.props.productionId + "/comments")
			.where("reply", "==", id)
			.get();

		if (replies.docs.length) {
			const batch = db.batch();
			replies.docs.forEach((reply) => {
				const ref = db.doc("productions/" + this.props.productionId + "/comments/" + reply.id);
				batch.update(ref, newData);
			});
			batch.commit();
		}
	};

	setRecipient = (e, data) => {
		const recipient = { ...this.state.recipient };

		if (this.state.recipient[data.recipient]) {
			delete recipient[data.recipient];

			// admin must always know!
			if (data.recipient === "prodAdmin" && !this.props.internalPublisher) {
				delete recipient.prodStaff;
				delete recipient.reader;

				// readers and producers can't send to only publisher
				if (!this.props.isPublisher) delete recipient.publisher;
			}
		} else {
			recipient[data.recipient] = true;

			// admin must always know!
			if (!this.props.internalPublisher) recipient.prodAdmin = true;
		}
		this.setState({ recipient });
	};

	setCompletionReminder = (id, state) => {
		base.updateDoc("productions/" + this.props.productionId + "/comments/" + id, { completionReminder: !!state })
			.then((data) => {})
			.catch((err) => {
				console.error("Attention error", err);
			});
	};

	render() {
		this.lastRender = +new Date();
		const { recipient, comments, seriesComments, newComment, seriesProductions } = this.state;
		const { t, store, production, productionId, people, internalPublisher, subcontractor } = this.props;
		const { profile, user, users: userMap } = store.state;
		const { uid } = user;

		let latestComment = "";
		comments.forEach((comment) => {
			if (!latestComment && comment.time) latestComment = comment;
			else if (comment?.time?.toDate() > latestComment?.time?.toDate()) latestComment = comment;
		});

		production.subcontractor = subcontractor;
		const productionPermissions = getProductionPermissions(store, production);
		const {
			isReader,
			isProducer,
			isPublisher,
			rights: productionRights,
			isRecorder,
			isManager,
			isProofer,
			isEditor,
			isSubcontractor,
		} = productionPermissions;
		const isAdmin =
			profile?.permissions?.producer?.[production.producer]?.includes?.("producerAdmin") ||
			profile?.permissions?.producer?.[production.producer]?.includes?.("producerStaff");
		const publisher = store?.state?.organizations?.[production.publisher]?.name;
		const isWorkGroup = isProofer || isRecorder || isEditor;

		let hasRecipient = Object.keys(recipient).length;

		// publisher and reader must choose one more than publisher
		if (hasRecipient === 1 && !internalPublisher && !isProducer && recipient.publisher) {
			hasRecipient = false;
		}

		const recipientData = [
			{
				icon: "key",
				text: t("prodAdmin"),
				recipient: "prodAdmin",
				disabled: !(isPublisher && recipient.publisher && !recipient.reader),
			},
			{
				icon: "headphones",
				text: t("prodStaff"),
				recipient: "prodStaff",
				hidden: (isSubcontractor || isPublisher) && !isAdmin,
				disabled: !!isWorkGroup && !isAdmin && !isManager,
			},
			{
				icon: "book",
				text: t("publisherManager"),
				recipient: "publisher",
				hidden: !!isSubcontractor,
				disabled: !!isPublisher,
			},
			{
				icon: "microphone",
				text: t("narrator"),
				recipient: "reader",
				hidden: !!isSubcontractor,
				disabled: !!isReader,
			},
			{
				icon: "file",
				text: t("subcontractor"),
				recipient: "subcontractor",
				hidden: !isAdmin,
			},
		];

		let filteredRecipients = recipientData.filter((btn) => !btn.hidden);

		if (internalPublisher) {
			// no buttons needed for internal
			filteredRecipients = [];
		}

		return (
			<Segment basic padded loading={this.state.loading}>
				{isProducer &&
					people &&
					Object.values(people)
						.filter((person) => person.email || person.phone)
						.map((person, i) => (
							<div style={{ display: "inline-block", fontSize: ".9em" }} key={i}>
								<p>
									<b>
										{person.type === "author" ? t("author") : t("translator")} {person.firstName}{" "}
										{person.lastName}:{" "}
									</b>
									{person.email && (
										<a
											href={"mailto:" + person.email}
											style={{ display: "inline-block", marginRight: "1em" }}
										>
											<Icon name="mail" /> {person.email}
										</a>
									)}
									{person.phone && (
										<a href={"tel:" + person.phone}>
											<Icon name="phone" />
											{formatPhoneNumberIntl(person.phone) || person.phone}
										</a>
									)}
								</p>
							</div>
						))}

				{seriesComments && !!seriesComments.length && (
					<Comment.Group
						className="production-comments"
						style={{ background: "#f9f7ee", padding: "5px 8px", borderRadius: 5 }}
					>
						{seriesComments
							.filter(
								(comment) =>
									!comment.reply &&
									(!isReader || !comment.readerUsers || comment.readerUsers.includes(uid)),
							)
							.sort((a, b) => (a.time > b.time ? 1 : -1))
							.map((comment) => (
								<ProductionSingleComment
									key={comment.id}
									comment={comment}
									userMap={userMap}
									uid={uid}
									internalPublisher={internalPublisher}
									production={production}
									productionId={productionId}
									profile={profile}
									publisher={publisher}
									seriesTitle={seriesProductions?.[comment.productionId]?.title}
									isProducer={isProducer}
									isPublisher={isPublisher}
									productionRights={productionRights}
									recipientData={recipientData}
									replies={seriesComments
										.filter((replies) => replies.reply && replies.reply === comment.id)
										.sort((a, b) => (a.time > b.time ? 1 : -1))}
								/>
							))}
					</Comment.Group>
				)}

				<Comment.Group className="production-comments clear">
					{comments
						.filter(
							(comment) =>
								!comment.reply &&
								(!isReader || !comment.readerUsers || comment.readerUsers.includes(uid)),
						)
						.sort((a, b) => (a.time > b.time ? 1 : -1))
						.map((comment, i) => (
							<ProductionSingleComment
								key={comment.id}
								parent={this}
								comment={comment}
								userMap={userMap}
								uid={uid}
								internalPublisher={internalPublisher}
								profile={profile}
								production={production}
								productionId={productionId}
								publisher={publisher}
								isProducer={isProducer}
								isPublisher={isPublisher}
								filteredRecipients={filteredRecipients}
								productionRights={productionRights}
								recipientData={recipientData}
								thumbComment={this.thumbComment}
								deleteComment={this.deleteComment}
								setAdminAttention={this.setAdminAttention}
								setCompletionReminder={this.setCompletionReminder}
								setSeriesComment={this.setSeriesComment}
								setCommentRecipient={this.setCommentRecipient}
								submitComment={this.submitComment}
								replies={comments
									.filter((replies) => replies.reply && replies.reply === comment.id)
									.sort((a, b) => (a.time > b.time ? 1 : -1))}
							/>
						))}

					{!!comments.length && (
						<Button
							icon
							labelPosition="left"
							size="mini"
							color={newComment ? "grey" : "orange"}
							style={{ cursor: "pointer", marginTop: "1em" }}
							onClick={(e) => {
								this.setState({ newComment: !newComment });
								setTimeout(this.scrollTo, 50);
							}}
							id="comment-button"
						>
							<Icon name={newComment ? "x" : "comment plus outline"} />{" "}
							{!newComment ? t("newComment") : t("close")}
						</Button>
					)}

					<Button
						icon
						labelPosition="left"
						size="mini"
						color={"grey"}
						style={{ cursor: "pointer", marginTop: "1em" }}
						onClick={() => {
							this.goToLatest(latestComment.id);
						}}
					>
						<Icon name="comment exclamation outline" /> {t("latestComment")}
					</Button>

					{(newComment || !comments.length) && (
						<>
							<Form
								loading={this.state.posting}
								style={{ overflow: "hidden", marginTop: "1em" }}
								onSubmit={this.submitComment}
							>
								{!internalPublisher && (
									<div className="clear">
										{production.series && (
											<Icon
												color={this.state.seriesComment ? "teal" : "grey"}
												name="stack overflow"
												style={{ cursor: "pointer", fontSize: "1.3em" }}
												onClick={(e, data) =>
													this.setState({ seriesComment: !this.state.seriesComment })
												}
											/>
										)}

										<Label
											style={{ float: "right", marginBottom: 4 }}
											content={
												this.state.recipient && Object.keys(this.state.recipient).length ? (
													// span is needed for css selector
													<span>
														{Object.values(recipientData).reduce((prev, curr) => {
															// skip publishers own label or unselected recipient
															if (!this.state.recipient[curr.recipient]) return prev;

															// build string for others
															const rec = curr.text;

															if (prev) {
																prev += ", " + rec.toLowerCase();
															} else {
																prev = rec;
															}

															return prev;
														}, "")}
													</span>
												) : (
													""
												)
											}
											icon="eye"
											size="tiny"
										/>
									</div>
								)}
								<Form.TextArea required name="commentText" placeholder={t("writeNewComment")} />

								<Button
									content={this.state.uploadText || t("send")}
									floated="right"
									size="tiny"
									disabled={!!this.state.uploadText || !hasRecipient}
									primary
								/>

								<div style={{ width: "calc(100% - 80px)", float: "left" }}>
									{!internalPublisher && (
										<Form.Group inline className="comment-recipient">
											<b>
												<Icon name="eye" /> {t("visibleFor")}
												{": "}
											</b>
											{filteredRecipients.map((btn) => (
												<Form.Checkbox
													key={btn.recipient}
													size="tiny"
													checked={!!this.state.recipient[btn.recipient]}
													label={btn.text}
													disabled={btn.disabled}
													recipient={btn.recipient}
													onClick={this.setRecipient}
												/>
											))}
										</Form.Group>
									)}
									{production.series && (
										<Form.Checkbox
											label={t("showForOtherBooks")}
											name="seriesComment"
											className="series-checkbox"
											checked={this.state.seriesComment}
											onChange={(e, data) => this.setState({ seriesComment: data.checked })}
										/>
									)}
									{!this.state.uploadedFileName ? (
										<UploadButton text={t("attacheFile")} size="small" onUpload={this.uploadFile} />
									) : (
										<small>
											<strong>{t("attachedFile")} </strong>
											<a
												href={this.state.uploadedFile}
												target="_blank"
												rel="noopener noreferrer"
												style={{ fontWeight: "bold" }}
											>
												<Icon fitted name="file" /> {this.state.uploadedFileName}
											</a>{" "}
											<Icon
												name="delete"
												color="grey"
												style={{ cursor: "pointer" }}
												onClick={(e) => {
													this.setState({ uploadedFile: null, uploadedFileName: null });
												}}
											/>
										</small>
									)}
								</div>
								{internalPublisher && (
									<small style={{ width: 210, float: "right", textAlign: "right" }}>
										<em>{t("importantPublisherMessage")} </em>
									</small>
								)}
							</Form>
						</>
					)}
				</Comment.Group>
			</Segment>
		);
	}
}

export default withTranslation()(withStore(ProductionComments));
