import React from "react";

import { useCollectionData } from "astrid-firestore/src/hooks";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";
import PageView from "../../../ui/views/PageView/PageView";

import OrdersAccordion from "../../../orders/components/OrdersAccordion/OrdersAccordion";
import useOrdersQuery from "../../../orders/hooks/useOrdersQuery";

export default function AccountOrdersView({ user, expense = true, header }) {
	const [orders = [], loading, error] = useCollectionData(
		useOrdersQuery({ expense, secondPartyId: user?.id || null })
			.orderBy("created", "desc")
			.limit(100),
	);

	return (
		<PageView header={header} maxWidth={1400}>
			<LoadingContext data={orders} loading={loading} error={error}>
				<OrdersAccordion orders={orders} />
			</LoadingContext>
		</PageView>
	);
}
