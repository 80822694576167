import { dotPick } from "astrid-firestore/src/utils/dotObject";

import renderChildren from "../../../utils/renderChildren";

export default function TableCellValue({ data, row, column }) {
	// TODO: Deprecate accessor in favor of Cell
	const value = column.accessor ? column.accessor(row) : dotPick(column.id, row);
	const cell = column.Cell ? renderChildren(column.Cell, { data, row, value, column }) : value;

	return cell;
}
