import artifactProductionStatuses from "astrid-firestore/src/api/artifacts/constants/artifactProductionStatuses";

import Label from "../../../ui/components/Label/Label";

import useArtifactProductionStatuses from "../../hooks/useArtifactProductionStatuses";

export default function ArtifactProductionStatusLabel({ artifact, ...props }) {
	const statuses = useArtifactProductionStatuses();

	const status = getProductionStatus(artifact);

	const { text, color } = statuses[status];

	return (
		<Label color={color} {...props}>
			{text}
		</Label>
	);
}

function getProductionStatus(artifact) {
	if (artifact.work) {
		return artifactProductionStatuses.DELIVERED;
	}

	return artifactProductionStatuses.NOT_DELIVERED;
}
