import runTransaction from "../../utils/runTransaction";
import updateDocumentData from "../../utils/updateDocumentData";

import updateArticle from "../articles/updateArticle";

import { Artifact } from "./types/Artifact";

export default function updateArtifact(firebase, data, filesToDelete) {
	return runTransaction(firebase, [data.article?.ref], async (transaction, [article]) => {
		if (filesToDelete) {
			for (const file of filesToDelete) {
				transaction.delete(file.ref);
			}
		}

		if (data.files) {
			data.size = data.files.reduce((sum, file) => sum + (file.size || 0), 0);
			data.duration = data.files.reduce((acc, file) => acc + (file.duration || 0), 0);

			data.files = data.files.map((file) => file.id);
		}

		// Update the article (duration) if this is the default artifact
		if (article?.artifact?.id === data.ref.id && data.duration) {
			await updateArticle(firebase, { ref: article.ref, duration: data.duration }, { transaction });
		}

		transaction.set(data.ref, Artifact.partial().parse(updateDocumentData(firebase, data)), { merge: true });
	});
}
