import { Button, Grid, Transition } from "semantic-ui-react";

import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";
import productionTypes from "astrid-firestore/src/api/productions/constants/productionTypes";
import useCollectionData from "astrid-firestore/src/hooks/useCollectionData";

import DataTable from "../../../ui/components/DataTable/DataTable";
import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import { useProfile } from "../../../authentication/state/profile";
import useSelectedColumns from "../../../listproductions/views/ListProductionsView/hooks/useSelectedColumns";

import MyProductionsAccordion from "../../components/MyProductionsAccordion";
import useMyProductionColumns from "../../hooks/useMyProductionColumns";
import getInitialSelectedColumns from "../../utils/getInitialSelectedColumns";

import useMapMyProductions from "./hooks/useMapMyProductions";
import useMyProductionsQuery from "./hooks/useMyProductionsQuery";

export default function OngoingProductionsView({ organization }) {
	const { t } = useTranslation();

	const profile = useProfile();
	const navigate = useNavigate();

	// Temporary fix, we should implement algolia here
	const defaultSort = organization.type === organizationTypes.PUBLISHER ? "deliveryDate" : "productionDate";
	const isReader = organization.type === organizationTypes.NARRATOR;
	const readerProductionTypes = [productionTypes.INTERNAL, productionTypes.SUBCONTRACT];

	const [myProductions, loading, error] = useCollectionData(
		useMyProductionsQuery({ organization, userId: profile.id }),
	);

	const initialColumns = useMemo(() => getInitialSelectedColumns(organization.type), [organization.type]);

	const [selectedColumns, selectColumn] = useSelectedColumns(initialColumns.storageKey, initialColumns.defaults);

	const [showRefinements, setShowRefinements] = useState(false);

	const columns = useMyProductionColumns({ profile, organization, selectedColumns });

	const mappedProductions = useMapMyProductions(
		isReader
			? myProductions?.filter((production) => readerProductionTypes.includes(production.productionType))
			: myProductions,
	);

	return (
		<LoadingContext
			data={mappedProductions}
			loading={loading}
			error={error}
			noDataAvailableMessage={t("noOngoingProductions")}
		>
			<Grid stackable columns="2">
				<Grid.Row>
					<Grid.Column width={showRefinements ? 3 : 1}>
						<Button
							icon={showRefinements ? "hide" : "unhide"}
							onClick={() => {
								setShowRefinements(!showRefinements);
							}}
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Transition visible={showRefinements} duration={500} animation="slide right">
						<Grid.Column
							mobile={16}
							tablet={16}
							computer={3}
							style={showRefinements ? { paddingRight: 0 } : { left: "-100%", position: "absolute" }}
						>
							<MyProductionsAccordion selectedColumns={selectedColumns} selectColumn={selectColumn} />
						</Grid.Column>
					</Transition>
					<Grid.Column mobile={16} tablet={16} computer={showRefinements ? 13 : 16} style={{ flexGrow: 1 }}>
						<DataTable
							persist
							sortable
							columns={columns}
							data={mappedProductions}
							onClick={({ id }) => navigate(`/production/${id}`)}
							sortBy={{ id: defaultSort, desc: false }}
						/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</LoadingContext>
	);
}
