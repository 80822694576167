import React from "react";
import { useTranslation } from "react-i18next";

import { timestampColumn } from "../../ui/utils/columns";

import ArticleDeliveryStatus from "../components/ArticleDeliveryStatus/ArticleDeliveryStatus";
import ArticleItem from "../components/ArticleItem/ArticleItem";
import ArticleProductionStatusLabel from "../components/ArticleProductionStatus/ArticleProductionStatusLabel";
import ArticleType from "../components/ArticleType/ArticleType";

export default function useArticlesTableColumns({ publisher } = {}) {
	const { t } = useTranslation();

	return [
		{
			id: "name",
			collapsing: true,
			Header: t("title", "Title"),
			Cell: ({ row }) => <ArticleItem article={row} />,
		},
		{
			id: "isbn",
			Header: t("isbn", "ISBN"),
			collapsing: true,
		},
		{
			id: "type",
			Header: t("type", "Type"),
			collapsing: true,
			Cell: ({ row }) => <ArticleType article={row} />,
		},
		!publisher && {
			id: "publisher.name",
			Header: t("publisher", "Publisher"),
			collapsing: true,
		},
		{
			id: "serie",
			Header: t("serie", "Serie"),
			collapsing: true,
			Cell: ({ row }) => {
				const { serie, season, serieNumber } = row;

				return (
					<>
						{serie && `${serie.name}`}
						{season && `, ${season.name}`}
						{serieNumber && `, ${t("part", "Part")} ${serieNumber}`}
					</>
				);
			},
		},
		{
			id: "status",
			collapsing: true,
			disableSortBy: true,
			Header: t("distribution", "Distribution"),
			Cell: ({ row }) => <ArticleDeliveryStatus article={row} />,
		},
		timestampColumn({ id: "deliveryDate", Header: t("deliveryDate", "Delivery date"), time: false }),
		{
			id: "productionStatus",
			Header: t("production", "Production"),
			collapsing: true,
			Cell: ({ row }) => <ArticleProductionStatusLabel article={row} />,
		},
		timestampColumn({ id: "updated", Header: t("updated", "Updated"), time: false }),
		timestampColumn({ id: "created", Header: t("created", "Created"), time: false }),
		// hasRight("articles.view") && dropdownMenuColumn(dropdownMenuOptions),
	];
}
