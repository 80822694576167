import { useDebounce } from "use-debounce";

import React, { useMemo } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline/index";

import ArtifactAudioPlayerTimelineChunk from "./ArtifactAudioPlayerTimelineChunk";

export default function ArtifactAudioPlayerTimelineChunks({ chunks }) {
	const [scope] = useDebounce(Timeline.useScope(), 300);

	const filteredChunks = useMemo(
		() =>
			chunks.filter((chunk) => {
				return chunk.stop > scope.start && chunk.start < scope.end;
			}),
		[chunks, scope.start, scope.end],
	);

	return filteredChunks.map((chunk) => {
		return <ArtifactAudioPlayerTimelineChunk key={chunk.start} chunk={chunk} />;
	});
}
