import { updateDocument } from "../../helpers/index";

import articleDeliveryStatuses from "../articles/constants/articleDeliveryStatuses";
import updateArticle from "../articles/updateArticle";

import updateArtifact from "./updateArtifact";

export default async function deliverArtifacts(firebase, { production, articles, artifacts }) {
	const article = articles.length === 1 ? articles[0] : null;
	const artifact = artifacts.length === 1 ? artifacts[0] : null;

	// Add all artifacts to the work & article
	await Promise.all(
		artifacts.map((artifact) =>
			updateArtifact(firebase, {
				ref: artifact.ref,
				work: production.work,
				article,
			}),
		),
	);

	// If there is only one artifact, auto-select the artifact
	if (article && artifact) {
		await updateArticle(firebase, {
			ref: article.ref,
			artifact,
		});
	}

	if (production.status !== "done") {
		await updateDocument(production.ref, { status: "done" });
		const channelStatusesToUpdate = Object.entries(article.channels || {})
			// should update non delivered channels to delivery needed (to notify the publisher)
			.filter(([, { delivery }]) => delivery.artifact.status === articleDeliveryStatuses.DELIVERY_NOT_STARTED)
			.reduce((acc, [channelId]) => {
				return {
					...acc,
					[`channels.${channelId}.delivery.artifact.status`]: articleDeliveryStatuses.DELIVERY_NEEDED,
				};
			}, {});
		if (Object.keys(channelStatusesToUpdate).length > 0) {
			await updateDocument(article.ref, channelStatusesToUpdate);
		}
	}
}
