import { useTranslation } from "react-i18next";

import OpenButton from "../../../ui/components/OpenButton/OpenButton";

import AddArticleForm from "./AddArticleForm";

export default function AddArticleButton({ work, publisher, production, ...props }) {
	const { t } = useTranslation();

	return (
		<OpenButton text={t("addArticle", "Add article")} {...props}>
			{({ setOpen }) => (
				<AddArticleForm
					work={work}
					publisher={publisher}
					production={production}
					onClose={() => setOpen(false)}
				/>
			)}
		</OpenButton>
	);
}
