import { useTranslation } from "react-i18next";

import artifactValidationStatuses from "astrid-firestore/src/api/artifacts/constants/artifactValidationStatuses";

export default function useArtifactValidationStatuses() {
	const { t } = useTranslation();

	return {
		[artifactValidationStatuses.PENDING]: {
			text: t("pending", "Pending"),
			color: "blue",
		},
		[artifactValidationStatuses.VALIDATING]: {
			text: t("validating", "Validating"),
			color: "blue",
		},
		[artifactValidationStatuses.VALID]: {
			text: t("valid", "Valid"),
			color: "green",
		},
		[artifactValidationStatuses.INVALID]: {
			text: t("invalid", "Invalid"),
			color: "red",
		},
		[artifactValidationStatuses.FAILED]: {
			text: t("failed", "Failed"),
			color: "red",
		},
	};
}
