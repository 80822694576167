import { Modal } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import Button from "../../../../ui/components/Buttons/Button";

import CalendarFilterForm from "../../../components/CalendarFilterForm/CalendarFilterForm";

export default function CalendarFilterFormModal({ open, onClose, form, organization }) {
	const { t } = useTranslation();

	return (
		<Modal open={open} onClose={onClose} closeOnDimmerClick>
			<Modal.Content>
				<CalendarFilterForm form={form} organization={organization} />
			</Modal.Content>

			<Modal.Actions>
				<Button onClick={onClose}>{t("ready", "Done")}</Button>
			</Modal.Actions>
		</Modal>
	);
}
