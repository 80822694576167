import React from "react";

import Flex from "../../../../components/Flex/Flex";
import CoverImage from "../../../ui/components/CoverImage/CoverImage";
import themeColors from "../../../ui/constants/themeColors";

export default function ArticleItem({ article, coverSize = 45, ...props }) {
	return (
		<Flex justifyContent="flex-start" {...props}>
			<div style={{ marginRight: "1em", height: coverSize, width: coverSize }}>
				<CoverImage thumb rounded data={article} size={coverSize} style={{ margin: "0 auto" }} />
			</div>

			<div>
				{article.name} {article.episodeName && ` - ${article.episodeName}`}
				<div style={{ color: themeColors.GREY }}>{article.subtitle}</div>
			</div>
		</Flex>
	);
}
