import createDocumentData from "../../utils/createDocumentData";
import runTransaction from "../../utils/runTransaction";

import { Imprint } from "../imprints/types/Imprint";
import { Work } from "../works/types/Work";
import { WorkMetaData } from "../works/types/WorkMetaData";

import getArticleChannelPresets from "./getArticleChannelPresets";
import { Article } from "./types/Article";
import validateArticleISBN from "./validateArticleISBN";

export default async function createArticle(firebase, data) {
	if (!data.publisher) {
		throw new Error("Publisher is missing");
	}

	const articleChannelPresets = await getArticleChannelPresets(firebase, data);

	return runTransaction(
		firebase,
		[data.publisher.ref, data.work?.ref, data.serie?.ref, data.imprint?.ref, data.production?.ref],
		async (transaction, [publisher, work, serie, imprint]) => {
			// If the work exists, add its metadata to the article
			if (work?.exists) {
				data = { ...WorkMetaData.parse(work), ...data };
			}

			// If the work does not exist, create it
			if (work && !work?.exists) {
				transaction.set(work.ref, Work.parse({ ...data, ...data.work, publisher }));
			}

			// If the serie does not exist, create it
			if (serie && !serie?.exists) {
				transaction.set(serie.ref, Imprint.parse({ ...data.serie, publisher }));
			}

			// If the imprint does not exist, create it
			if (imprint && !imprint?.exists) {
				transaction.set(imprint.ref, Imprint.parse({ ...data.imprint, publisher }));
			}

			// Create the article document
			const article = Article.parse(
				createDocumentData(firebase, "articles", {
					...data,
					...articleChannelPresets,
				}),
			);

			// Validate the article ISBN
			if (data.isbn) {
				await validateArticleISBN({ ref: article.ref, isbn: data.isbn });
			}

			// Add the article to the production
			if (data.production) {
				transaction.update(data.production.ref, {
					articleIds: firebase.firestore.FieldValue.arrayUnion(article.id),
				});
			}

			transaction.set(article.ref, article);

			return article;
		},
	);
}
