import React from "react";
import { useTranslation } from "react-i18next";

import DropdownMenu from "../../../ui/components/DropdownMenu/DropdownMenu";
import ErrorMessage from "../../../ui/components/Messages/ErrorMessage";
import useBreadcrumbs from "../../../ui/hooks/useBreadcrumbs";
import PageView from "../../../ui/views/PageView/PageView";

import ArtifactStatusLabels from "../../components/ArtifactStatus/ArtifactStatusLabels";
import useArtifactDropdownMenuOptions from "../../hooks/useArtifactDropdownMenuOptions";

export default function ArtifactView({ work, article, artifact, production, children }) {
	const { t } = useTranslation();

	const breadcrumbs = useBreadcrumbs({ work, article, artifact, production });

	const dropdownMenuOptions = useArtifactDropdownMenuOptions({ article, production });

	return (
		<PageView
			maxWidth={1400}
			breadcrumbs={breadcrumbs}
			header={artifact.name}
			subheader={t("amountFiles", "{{amount}} files", { amount: artifact.files.length })}
			extra={
				<>
					<ArtifactStatusLabels artifact={artifact} article={article} production={production} />

					<DropdownMenu options={dropdownMenuOptions(artifact)} />
				</>
			}
		>
			{artifact.error && <ErrorMessage>{artifact.error}</ErrorMessage>}

			{children}
		</PageView>
	);
}
