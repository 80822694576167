import { Header, Label } from "semantic-ui-react";

import React from "react";

import offerHasNotification from "astrid-firestore/src/api/offers/offerHasNotification";

import Flex from "../../../../components/Flex/Flex";
import Accordion, { AccordionTitle } from "../../../ui/components/Accordion/Accordion";

import { useProfile } from "../../../authentication/state/profile";

import useOfferTypeTranslations from "../../hooks/useOfferTypeTranslations";

import OfferStatusLabel from "../OfferStatus/OfferStatusLabel";

export default function OfferAccordion({ offers, onClickOffer, selectedOffer }) {
	const user = useProfile();
	const offerTypeTranslations = useOfferTypeTranslations();

	return (
		<Accordion styled style={{ position: "sticky", top: 12 }}>
			{offers.map((offer) => {
				const { production } = offer;

				return (
					<AccordionTitle
						key={offer.id}
						onClick={() => onClickOffer(offer)}
						style={{ backgroundColor: selectedOffer?.id === offer.id ? "#f4fafd" : "white" }}
					>
						<Label
							style={{
								margin: "0 -5px",
								backgroundColor: !offerHasNotification(offer, user.id) && "transparent",
							}}
							circular
							color={offerHasNotification(offer, user.id) ? "blue" : "white"}
							empty
							size="mini"
						/>
						<Flex
							style={{
								gap: 15,
								justifyContent: "flex-start",
								whiteSpace: "nowrap",
								overflow: "hidden",
							}}
						>
							<Header
								as="h4"
								style={{
									margin: 0,
									textOverflow: "ellipsis",
									overflow: "hidden",
								}}
							>
								{production.title}

								<Header.Subheader style={{ marginTop: 4 }}>
									{offerTypeTranslations[offer.type]}
								</Header.Subheader>
							</Header>
						</Flex>

						<OfferStatusLabel size="small" offer={offer} />
					</AccordionTitle>
				);
			})}
		</Accordion>
	);
}
