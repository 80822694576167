import React, { useMemo, useState } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";
import Divider from "astrid-components/lib/components/Layout/Divider";
import Panel from "astrid-components/lib/components/Layout/Panel";
import Theme from "astrid-components/lib/components/Theme";
import flattenArtifactSilences from "astrid-firestore/src/api/artifacts/flattenArtifactSilences";

import Flex from "../../../../components/Flex/Flex";

import ArtifactAudioPlayerMarkerList from "./components/ArtifactAudioPlayerMarkerList";
import ArtifactAudioPlayerTimeline from "./components/ArtifactAudioPlayerTimeline";
import ArtifactAudioPlayerToolbar from "./components/ArtifactAudioPlayerToolbar";
import useArtifactAudioPlayerChunks from "./hooks/useArtifactAudioPlayerChunks";
import useArtifactAudioPlayerControls from "./hooks/useArtifactAudioPlayerControls";
import useArtifactAudioPlayerMarkers from "./hooks/useArtifactAudioPlayerMarkers";

export default function ArtifactAudioPlayer({ files, markers, onAddMarker, onUpdateMarker, onRemoveMarker }) {
	const [activeTool, setActiveTool] = useState(null);

	const chunks = useArtifactAudioPlayerChunks({ files });
	const silences = useMemo(() => flattenArtifactSilences(files), [files]);

	const { play, pause, action } = useArtifactAudioPlayerControls({ chunks });

	const mappedMarkers = useArtifactAudioPlayerMarkers({ files, markers });

	return (
		<Theme mode="semantic">
			<Flex>
				<Panel style={{ height: 300, flex: 1, margin: -1 }} overflow="hidden" background="secondary">
					<ArtifactAudioPlayerToolbar
						play={play}
						pause={pause}
						action={action}
						activeTool={activeTool}
						setActiveTool={setActiveTool}
					/>

					<Divider />

					<ArtifactAudioPlayerTimeline
						play={play}
						chunks={chunks}
						action={action}
						silences={silences}
						markers={mappedMarkers}
						activeTool={activeTool}
						setActiveTool={setActiveTool}
						onAddMarker={onAddMarker}
						onUpdateMarker={onUpdateMarker}
					/>

					<Divider />

					<Timeline.Navigation />
				</Panel>

				{mappedMarkers.length > 0 && (
					<Panel style={{ height: 300, flex: 0.2, margin: -1 }} background="secondary">
						<ArtifactAudioPlayerMarkerList markers={mappedMarkers} onRemoveMarker={onRemoveMarker} />
					</Panel>
				)}
			</Flex>
		</Theme>
	);
}
