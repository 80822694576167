import firebase from "firebase";

import { useTranslation } from "react-i18next";

import updateArticle from "astrid-firestore/src/api/articles/updateArticle";
import artifactValidationStatuses from "astrid-firestore/src/api/artifacts/constants/artifactValidationStatuses";
import softDeleteDocument from "astrid-firestore/src/api/documents/softDeleteDocument";

import { useProfile } from "../../authentication/state/profile";

export default function useArtifactDropdownMenuOptions({ article, production }) {
	const { t } = useTranslation();

	const user = useProfile();

	return (artifact) => [
		article && {
			text:
				artifact.id !== article.artifact?.id
					? t("selectAsDefault", "Select as default")
					: t("unselect", "Unselect"),
			action: () => {
				return updateArticle(firebase, {
					ref: article.ref,
					artifact: artifact.id !== article.artifact?.id ? artifact : null,
				});
			},
		},
		{
			text: t("validate", "validate"),
			disabled: artifact.status === artifactValidationStatuses.PENDING,
			action: () => {
				return artifact.ref.update({
					status: artifactValidationStatuses.PENDING,
				});
			},
		},
		{
			text: t("delete", "Delete"),
			disabled: artifact.id === article?.artifact?.id || (production && artifact.work),
			confirmText: t("deleteArtifactConfirmation", "Are you sure you want to delete this artifact?"),
			action: () => {
				return softDeleteDocument(firebase, artifact, user);
			},
		},
	];
}
