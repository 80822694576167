import firebase from "firebase/app";

import { Button, Segment } from "semantic-ui-react";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { db } from "astrid-firebase";
import articleTypes from "astrid-firestore/src/api/articles/constants/articleTypes";
import productionStatuses from "astrid-firestore/src/api/productions/constants/productionStatuses";
import createProduction from "astrid-firestore/src/api/productions/createProduction";
import { Production } from "astrid-firestore/src/api/productions/types/Production";
import { useDocumentDataOnce } from "astrid-firestore/src/hooks";
import useLoadingValues from "astrid-firestore/src/hooks/useLoadingValues";

import Flex from "../../../../components/Flex/Flex";
import InstantSearchSelect from "../../../ui/components/InstantSearch/InstantSearchSelect";
import ErrorMessage from "../../../ui/components/Messages/ErrorMessage";

import useArticleTypeTranslations from "../../../articles/hooks/useArticleTypeTranslations";
import { useProfile } from "../../../authentication/state/profile";
import featureFlags from "../../../authorization/constants/featureFlags";
import useFeatureFlag from "../../../authorization/hooks/useFeatureFlag";
import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";
import usePublisherManagerOptions from "../../../users/hooks/usePublisherManagerOptions";

export default function CreateProductionPublisherForm({
	defaultWorkId,
	defaultArticleId,
	defaultProducerId = null,
	defaultPublisherId,
	setPublisherId,
	producerOptions,
	publisherOptions,
}) {
	const user = useProfile();

	const navigate = useNavigate();

	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	const { t } = useTranslation(["common", "language"]);

	const articleTypeTranslations = useArticleTypeTranslations();

	const { form, watch, formState, setValue, error, onSubmit } = useSimpleForm({
		schema: Production,
		values: {
			status: productionStatuses.DRAFT,
			workId: defaultWorkId || null,
			articleIds: defaultArticleId ? [defaultArticleId] : [],
			producerId: defaultProducerId,
			publisherId: defaultPublisherId,
			publisherManagers: [],
			title: "",
			language: "",
			createdBy: user.email,
		},
		onSubmit: (data) => {
			return createProduction(firebase, data);
		},
		onSuccess: ({ id }) => {
			navigate(`/production/${id}`);
		},
	});

	const { workId, publisherId } = watch();

	const [[work, publisher, publisherManagerOptions], loading] = useLoadingValues(
		useDocumentDataOnce(workId && db.collection("works").doc(workId)),
		useDocumentDataOnce(publisherId && db.collection("organizations").doc(publisherId)),
		usePublisherManagerOptions(publisherId),
	);

	useEffect(() => {
		setPublisherId?.(publisherId);
	}, [publisherId, setPublisherId]);

	useEffect(() => {
		setValue("title", work?.name || "");
	}, [setValue, work?.name]);

	useEffect(() => {
		setValue("language", work?.language || "");
	}, [setValue, publisherId, work?.language]);

	useEffect(() => {
		setValue(
			"publisherManagers",
			publisherManagerOptions?.filter((option) => option.value === user?.id).map((option) => option.data),
		);
	}, [setValue, publisherManagerOptions, user]);

	return (
		<>
			<Segment padded>
				<Form form={form}>
					<Form.Select
						name="publisherId"
						disabled={publisherOptions?.length <= 1}
						label={t("publisher", "Publisher")}
						search
						deburr
						options={publisherOptions}
					/>

					{hasFlatteningFeature && (
						<Form.Field name="workId" label={t("work", "Work")}>
							<InstantSearchSelect
								indexName="works"
								filters={`publisher.id:${publisherId}`}
								getOption={(data) => ({
									key: data.id,
									value: data.id,
									text: data.name,
								})}
								allowEmpty
							/>
						</Form.Field>
					)}

					<Form.Input name="title" label={t("title", "Title")} />

					<Form.LanguageSelect
						name="language"
						label={t("language", "Language")}
						disabled={!publisherId || !!workId}
						limitedOptions={publisher?.languageOptions}
						deburr
					/>

					{hasFlatteningFeature && (
						<Form.CollectionSelect
							idOnly
							multiple
							name="articleIds"
							label={t("articles", "Articles")}
							query={db
								.collection("articles")
								.where("work.id", "==", workId)
								.where("type", "in", [articleTypes.AUDIOBOOK, articleTypes.PODCAST])}
							mapOption={(article) => ({
								text: `[${article.isbn}] ${article.name} (${articleTypeTranslations[article.type]})`,
							})}
							disabled={!workId}
						/>
					)}

					<Form.Select
						name="producerId"
						disabled={producerOptions?.length <= 1}
						label={t("productionCompany", "Production company")}
						search
						deburr
						options={producerOptions}
					/>

					<Form.DocumentSelect
						name="publisherManagers"
						label={t("publisherManager", "Publisher manager")}
						disabled={!publisherId}
						multiple
						search
						deburr
						options={publisherManagerOptions}
						loading={loading}
					/>
				</Form>
			</Segment>

			{error && <ErrorMessage error={error} />}

			<Flex justifyContent="center">
				<Button
					loading={formState.isSubmitting}
					disabled={formState.isSubmitting}
					style={{ marginTop: 15, width: 200 }}
					primary
					content={t("create")}
					onClick={onSubmit}
				/>
			</Flex>
		</>
	);
}
