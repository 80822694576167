import { Segment } from "semantic-ui-react";

import { useState } from "react";
import { ReactReader } from "react-reader";

export default function ArtifactEpubReader({ files }) {
	const [location, setLocation] = useState();

	return (
		<Segment style={{ height: 500 }}>
			<ReactReader url={files[0].url} location={location} locationChanged={(epubcfi) => setLocation(epubcfi)} />
		</Segment>
	);
}
