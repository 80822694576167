import { Header } from "semantic-ui-react";

import useWindowSize from "react-use/lib/useWindowSize";

import Flex from "../../../../components/Flex/Flex";

import CalendarDateHeaderContent from "./components/CalendarDateHeaderContent";
import CalendarHeaderToolbarNavigation from "./components/CalendarHeaderToolbarNavigation";
import CalendarHeaderToolbarNavigationDropdown from "./components/CalendarHeaderToolbarNavigationDropdown";
import CalendarHeaderToolbarTools from "./components/CalendarHeaderToolbarTools";

export default function CalendarHeaderToolbar({
	disableTools,
	modifiedSessions,
	onOpenNotifyModal,
	onStopBooking,
	onClickBook,
	isBooking,
	onClickFilter,
	viewType,
	startDate,
	endDate,
	isEditing,
	onClickEdit,
	navigation,
	filterForm,
	resourceType,
}) {
	const { width } = useWindowSize();

	const desktop = width > 1200;

	return (
		<Flex style={{ alignItems: "center", marginBottom: 14 }}>
			{!disableTools && (
				<CalendarHeaderToolbarTools
					modifiedSessions={modifiedSessions}
					onOpenNotifyModal={onOpenNotifyModal}
					onStopBooking={onStopBooking}
					onClickBook={onClickBook}
					isBooking={isBooking}
					onClickFilter={onClickFilter}
					isEditing={isEditing}
					onClickEdit={onClickEdit}
					filterForm={filterForm}
					resourceType={resourceType}
					desktop={desktop}
				/>
			)}

			<Header size={viewType.includes("Week") ? "medium" : desktop ? "large" : "small"} style={{ margin: 0 }}>
				<CalendarDateHeaderContent endDate={endDate} startDate={startDate} viewType={viewType} />
			</Header>

			{desktop && (
				<CalendarHeaderToolbarNavigation
					navigation={navigation}
					viewType={viewType}
					disableTools={disableTools}
				/>
			)}

			{!desktop && (
				<CalendarHeaderToolbarNavigationDropdown
					navigation={navigation}
					viewType={viewType}
					disableTools={disableTools}
				/>
			)}
		</Flex>
	);
}
