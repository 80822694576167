import React from "react";
import { useTranslation } from "react-i18next";

import VerticalMenu from "../../../ui/components/VerticalMenu/VerticalMenu";
import PageView from "../../../ui/views/PageView/PageView";

import useSuperAdminSettingsMenuOptions from "./hooks/useSuperAdminSettingsMenuOptions";

export default function SuperAdminSettingsView({ context, ...props }) {
	const { t } = useTranslation();

	const options = useSuperAdminSettingsMenuOptions({ context });

	return <PageView header={t("settings", "Settings")} sidebar={<VerticalMenu options={options} />} {...props} />;
}
