import { Segment, Table as SemanticTable, TableBody, TableHeader, TableRow } from "semantic-ui-react";

import React from "react";

import renderChildren from "../../../utils/renderChildren";
import LoadingContext from "../../ui/components/LoadingContext/LoadingContext";

import useTable from "../hooks/useTable";

import TableCell from "./TableCell";
import TableCellCheckbox from "./TableCellCheckbox";
import TableCellDropdownMenu from "./TableCellDropdownMenu";
import TableCellValue from "./TableCellValue";
import TableColumnSelector from "./TableColumnSelector";
import TableContext from "./TableContext";
import TableHeaderCell from "./TableHeaderCell";
import TableHeaderCellCheckbox from "./TableHeaderCellCheckbox";

export default function Table({
	data,
	columns,
	onClick,
	children,
	loading = false,
	error = false,
	visibleColumns,
	enableExpander,
	enableCheckboxes,
	enableColumnSelector,
	getDropdownMenuOptions,
	...props
}) {
	const table = useTable({ data, columns, visibleColumns });

	const { filteredColumns } = table;

	return (
		<TableContext.Provider value={table}>
			<LoadingContext data={data} loading={loading} error={error}>
				<Segment
					attached={enableExpander && "top"}
					style={{ padding: 0, overflowX: "auto", scrollbarWidth: "thin" }}
					{...props}
				>
					<SemanticTable padded unstackable selectable={!!onClick} style={{ border: "none" }}>
						<TableHeader>
							<TableRow>
								{enableCheckboxes && <TableHeaderCellCheckbox enableCheckboxes={enableCheckboxes} />}

								{filteredColumns.map((column) => (
									<TableHeaderCell key={column.id} {...column}>
										{renderChildren(column.Header, { column })}
									</TableHeaderCell>
								))}

								{enableColumnSelector && <TableColumnSelector />}

								{!enableColumnSelector && getDropdownMenuOptions && <TableHeaderCell />}
							</TableRow>
						</TableHeader>

						<TableBody>
							{data.map((row) => {
								return (
									<TableRow key={row.id}>
										{enableCheckboxes && (
											<TableCellCheckbox row={row} enableCheckboxes={enableCheckboxes} />
										)}

										{filteredColumns.map((column) => (
											<TableCell
												key={column.id}
												onClick={onClick ? () => onClick(row) : undefined}
												{...column}
											>
												<TableCellValue row={row} column={column} data={data} />
											</TableCell>
										))}

										{getDropdownMenuOptions && (
											<TableCellDropdownMenu
												upward={row.index > data.length - 3}
												options={getDropdownMenuOptions(row)}
											/>
										)}

										{!getDropdownMenuOptions && enableColumnSelector && <TableHeaderCell />}
									</TableRow>
								);
							})}
						</TableBody>
					</SemanticTable>
				</Segment>

				{enableExpander && (
					<Segment transparent attached="bottom" textAlign="center" style={{ background: "none" }}></Segment>
				)}
			</LoadingContext>

			{renderChildren(children, table)}
		</TableContext.Provider>
	);
}
