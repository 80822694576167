import uniqid from "uniqid";

import FirestoreDocument from "../../../types/FirestoreDocument";
import FirestoreLifecycle from "../../../types/FirestoreLifecycle";
import { array, enumArray, enumValues, nullable, number, object, string } from "../../../types/_types";

import { ArticleRef } from "../../articles/types/ArticleRef";
import { OrganizationRef } from "../../organizations/types/OrganizationRef";
import { ProductionRef } from "../../productions/types/Production";
import { WorkRef } from "../../works/types/WorkRef";

import artifactMarkerTypes from "../constants/artifactMarkerTypes";
import artifactValidationStatuses from "../constants/artifactValidationStatuses";

import { artifactFileTypes } from "./ArtifactFile";

export const artifactStatus = enumValues(artifactValidationStatuses);
export const artifactMarkerType = enumValues(artifactMarkerTypes);

export const artifactMarker = object({
	id: string().default(() => uniqid()),
	fileId: string(),
	position: number(),
	text: string(),
	type: artifactMarkerType,
});

export const Artifact = FirestoreDocument.extend({
	work: nullable(WorkRef),
	article: nullable(ArticleRef),
	files: array(string()),
	format: nullable(object().passthrough()),
	contentType: nullable(enumArray(Object.keys(artifactFileTypes))),
	markers: array(artifactMarker),
	name: string(3),
	producer: nullable(OrganizationRef),
	production: nullable(ProductionRef),
	publisher: nullable(OrganizationRef),
	size: number().default(0),
	duration: number().default(0),
	status: artifactStatus.default(artifactValidationStatuses.PENDING),
}).merge(FirestoreLifecycle);
