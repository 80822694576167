import React, { useEffect } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";

import useArtifactAudioPlayerTimeline from "../hooks/useArtifactAudioPlayerTimeline";

import ArtifactAudioPlayerTimelineChunks from "./ArtifactAudioPlayerTimelineChunks";
import ArtifactAudioPlayerTimelineMarker from "./ArtifactAudioPlayerTimelineMarker";
import ArtifactAudioPlayerTimelineSilences from "./ArtifactAudioPlayerTimelineSilences";
import ArtifactAudioPlayerTimelineTool from "./ArtifactAudioPlayerTimelineTool";

export default function ArtifactAudioPlayerTimeline({
	action,
	activeTool,
	chunks,
	markers,
	onAddMarker,
	onUpdateMarker,
	play,
	setActiveTool,
	silences,
}) {
	const { setPosition, setDuration, resetTimeline } = useArtifactAudioPlayerTimeline();

	const onClickTimeline = (position) => {
		setPosition(position);

		if (action === "play") {
			play();
		}
	};

	useEffect(() => {
		const duration = chunks[chunks.length - 1]?.stop;

		if (duration) {
			setDuration(duration);
		}

		return resetTimeline;
	}, [chunks, resetTimeline, setDuration]);

	return (
		<Timeline onClick={onClickTimeline}>
			<Timeline.Scope value={2}>
				<Timeline.Ruler />

				<ArtifactAudioPlayerTimelineChunks chunks={chunks} />

				<ArtifactAudioPlayerTimelineSilences silences={silences} />

				{markers.map((marker, index) => {
					return (
						<ArtifactAudioPlayerTimelineMarker
							key={marker.id}
							index={index}
							marker={marker}
							onChange={(position) => onUpdateMarker({ index, marker, position })}
						/>
					);
				})}

				<Timeline.MouseCursor />

				{activeTool && (
					<ArtifactAudioPlayerTimelineTool
						activeTool={activeTool}
						setActiveTool={setActiveTool}
						addMarker={onAddMarker}
					/>
				)}

				<Timeline.PositionCursor />
			</Timeline.Scope>
		</Timeline>
	);
}
