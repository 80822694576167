import { Button, Divider, Icon, Modal } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import { db, firebase } from "astrid-firebase";
import offerStatuses from "astrid-firestore/src/api/offers/constants/offerStatuses";
import offerTypes from "astrid-firestore/src/api/offers/constants/offerTypes";
import createOffer from "astrid-firestore/src/api/offers/createOffer";
import notifyOffer from "astrid-firestore/src/api/offers/notifyOffer";
import { useDocumentDataOnce, useLoadingValues } from "astrid-firestore/src/hooks";
import stopPropagation from "astrid-helpers/src/stopPropagation";

import Flex from "../../../../../components/Flex/Flex";
import { useProfile } from "../../../../authentication/state/profile";
import useHasRight from "../../../../authorization/hooks/useHasRight";
import Form from "../../../../forms/components/Form/Form";
import { useOrganizationType } from "../../../../organizations/state/organizationType";
import CurrencySelect from "../../../../prices/components/CurrencySelect/CurrencySelect";
import PriceUnitSelect from "../../../../prices/components/PriceUnitSelect/PriceUnitSelect";
import ImageAvatar from "../../../../ui/components/ImageAvatar/ImageAvatar";
import LoadingContext from "../../../../ui/components/LoadingContext/LoadingContext";

import offerTypeIcons from "../../../constants/offerTypeIcons";
import useOfferTypeTranslations from "../../../hooks/useOfferTypeTranslations";

import OfferHeader from "../../OfferHeader/OfferHeader";

import useInquiryOfferForm from "../hooks/useInquiryOfferForm";

import ExpiresAtInput from "./ExpiresAtInput";

export default function InquiryOfferForm({ type, offer, production, children, onClose, ...props }) {
	const { t } = useTranslation();
	const hasRight = useHasRight();
	const organizationType = useOrganizationType();
	const user = useProfile();

	const [[firstParty], loading, error] = useLoadingValues(
		useDocumentDataOnce(db.collection("organizations").doc(production.producer)),
	);

	const types = useOfferTypeTranslations();
	const form = useInquiryOfferForm({ offer, firstParty });

	const { formState, handleSubmit } = form;

	const limitedCurrencies = [...(firstParty?.currencyOptions || []), offer?.price?.currency];

	const onSubmit =
		(status) =>
		async ({ price, expiresAt, optionalMessage, optionalMessageSender, discussionVisibleForPublisher }) => {
			const createdOffer = await createOffer(firebase, {
				id: offer?.id,
				role: offer?.role,
				voiceId: offer?.voiceId,
				type: offerTypes.INQUIRY,
				price,
				status,
				approved: true,
				expiresAt,
				production,
				secondParty: offer?.secondParty,
				createdBy: user,
				optionalMessage,
				optionalMessageSender,
				discussionVisibleForPublisher,
			});

			notifyOffer(createdOffer, t).then(
				(notoficationIds) => notoficationIds && console.log("Offer notifications sent:", notoficationIds),
			);

			onClose();
		};

	const optionalMessageSenderOptions = [
		{ key: production.publisher, value: production.publisher, text: production.publisherName },
		{ key: production.producer, value: production.producer, text: production.producerName },
		{ key: user.id, value: user.id, text: `${user.firstName} ${user.lastName}` },
	];

	return (
		<Modal open size="small" onClose={onClose} onKeyDown={stopPropagation} onClick={stopPropagation} {...props}>
			<Modal.Header>
				<Icon name={offerTypeIcons[type]} /> {types[type]}
			</Modal.Header>

			<Modal.Content>
				<Flex style={{ gap: 12, justifyContent: "flex-start", whiteSpace: "nowrap" }}>
					<ImageAvatar src={offer.secondParty.img} />
					<OfferHeader offer={offer} />
				</Flex>

				<Divider />

				<LoadingContext loading={loading} error={error}>
					<Form form={form}>
						<ExpiresAtInput name="expiresAt" label={t("expiresAt", "Expires at")} />
						<Form.Group widths="equal">
							<Form.NumberInput name="price.price" label={t("cost", "Cost")} />

							<Form.Field name="price.currency">
								<CurrencySelect label={t("currency", "Currency")} limitedOptions={limitedCurrencies} />
							</Form.Field>

							<Form.Field name="price.unit">
								<PriceUnitSelect label={t("unit", "Unit")} />
							</Form.Field>
						</Form.Group>
						<Form.TextArea
							name="optionalMessage"
							label={t("optionalMessage", "Optional message")}
							rules={{ required: false }}
						/>
						{hasRight("offers.send") && (
							<Form.Select
								name="optionalMessageSender"
								label={t("optionalMessageSender", "Optional message sender")}
								options={optionalMessageSenderOptions}
								rules={{ required: false }}
							/>
						)}
						{hasRight("offers.send") && (
							<Form.Checkbox
								name="discussionVisibleForPublisher"
								label={t(
									"makeOfferDiscussionVisibleForPublisher",
									"Make the discussion visible for publisher",
								)}
								rules={{ required: false }}
							/>
						)}
					</Form>
				</LoadingContext>
			</Modal.Content>

			<Modal.Actions>
				<Button content={t("cancel")} onClick={onClose} />

				<Button
					primary={!hasRight("offers.send")}
					content={organizationType === "publisher" ? t("save", "Save") : t("saveDraft", "Save draft")}
					disabled={!formState.isValid}
					loading={formState.isSubmitting}
					onClick={handleSubmit(onSubmit(offerStatuses.DRAFT))}
				/>

				{hasRight("offers.send") && (
					<Button
						primary
						content={t("send", "Send")}
						disabled={!formState.isValid}
						loading={formState.isSubmitting}
						onClick={handleSubmit(onSubmit(offerStatuses.OFFERED))}
					/>
				)}
			</Modal.Actions>
		</Modal>
	);
}
