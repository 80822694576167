import notify from "astrid-firebase/src/notifications";
import { isLocal, isStage } from "astrid-helpers/src/env";

import { teamRoles } from "../../constants/teamRoles";

import offerStatuses from "./constants/offerStatuses";
import offerTypes from "./constants/offerTypes";

export default async function notifyOffer(offer, t) {
	if (offer.status !== offerStatuses.OFFERED) {
		return;
	}

	const recipients = [offer.secondParty.id];

	return notify(recipients, (user) => getOfferNotificationData(user, offer, t));
}

function getOfferNotificationData(user, offer, t) {
	const lang = user.languageCode || "en";
	const offerExpirationDate = offer.expiresAt.toLocaleDateString(lang);
	const host = window.location.origin;
	const orgLink =
		offer.role === teamRoles.READER ? `narrator/${offer.secondParty.id}` : `producer/${offer.producer.id}`;
	const link =
		isLocal || isStage
			? `${host}/${orgLink}/my-productions/offers`
			: `https://astrid.fm/${orgLink}/my-productions/offers`;

	switch (offer.type) {
		case offerTypes.INQUIRY:
			return {
				subject: t("newInquiry", { lng: lang }) + offer.production.title,
				message: t("readerOfferMessage", {
					lng: lang,
					title: offer.production.title,
					lastDate: offerExpirationDate,
					webLink: link,
				}),
			};
		case offerTypes.WORK_ORDER:
			return {
				subject: t("newWorkOrder", { lng: lang }) + offer.production.title,
				message: t("workOrderOfferMessage", {
					lng: lang,
					title: offer.production.title,
					lastDate: offerExpirationDate,
					webLink: link,
				}),
			};
		default:
			return {};
	}
}
