import articleProductionStatuses from "astrid-firestore/src/api/articles/constants/articleProductionStatuses";

import Label from "../../../ui/components/Label/Label";

import useArticleProductionStatuses from "../../hooks/useArticleProductionStatuses";

export default function ArticleProductionStatusLabel({ article, ...props }) {
	const statuses = useArticleProductionStatuses();

	const { text, color } = statuses[article.productionStatus || articleProductionStatuses.NOT_STARTED];

	return (
		<Label color={color} {...props}>
			{text}
		</Label>
	);
}
