import artifactSelectionStatuses from "astrid-firestore/src/api/artifacts/constants/artifactSelectionStatuses";

import Label from "../../../ui/components/Label/Label";

import useArtifactSelectionStatuses from "../../hooks/useArtifactSelectionStatuses";

export default function ArtifactSelectionStatusLabel({ article, artifact, ...props }) {
	const statuses = useArtifactSelectionStatuses();

	const status = getSelectionStatus({ article, artifact });

	const { text, color } = statuses[status];

	return (
		<Label color={color} {...props}>
			{text}
		</Label>
	);
}

function getSelectionStatus({ article, artifact }) {
	if (article.artifact?.id === artifact.id) {
		return artifactSelectionStatuses.SELECTED;
	}

	return artifactSelectionStatuses.NOT_SELECTED;
}
