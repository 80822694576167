import { Checkbox } from "semantic-ui-react";

import React, { useContext } from "react";

import TableContext from "./TableContext";
import TableHeaderCell from "./TableHeaderCell";

export default function TableHeaderCellCheckbox({ enableCheckboxes }) {
	const { data, selectedRowIds, setSelectedRowIds } = useContext(TableContext);

	const selectableRowIds = data
		.filter((row) => (typeof enableCheckboxes === "function" ? enableCheckboxes(row) : true))
		.map(({ id }) => id);

	const onClick = () => {
		setSelectedRowIds((selectedRowIds) => (selectedRowIds.length === data.length ? [] : selectableRowIds));
	};

	return (
		<TableHeaderCell first sticky collapsing onClick={selectableRowIds.length > 0 ? onClick : undefined}>
			<Checkbox
				checked={selectedRowIds.length === data.length}
				disabled={selectableRowIds.length === 0}
				indeterminate={selectedRowIds.length > 0 && selectedRowIds.length < data.length}
				// onChange={(_, { checked }) => {
				// 	setSelectedRowIds(checked ? data.map(({ id }) => id) : []);
				// }}
			/>
		</TableHeaderCell>
	);
}
