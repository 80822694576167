import { Icon } from "semantic-ui-react";

import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";
import MyProductionsDropdownMenu from "astrid-web/src/features/home/components/MyProductionsWidget/components/MyProductionsDropdownMenu";
import { useOrganizationType } from "astrid-web/src/features/organizations/state/organizationType";

import Flex from "../../../../../components/Flex/Flex";
import ProgressEdit from "../../../../../components/production/ProgressEdit";
import ProgressRecording from "../../../../../components/production/ProgressRecording";
import { prodStatus } from "../../../../../helpers/lists";

import mapProductionStatus from "../../../helpers/mapProductionStatus";

export default function useMyProductionsColumns() {
	const { t } = useTranslation();
	const orgType = useOrganizationType();

	return useMemo(
		() => [
			{
				id: "title",
				Header: t("title", "Title"),
			},
			{
				id: "status",
				Header: t("status", "Status"),
				collapsing: true,
				Cell: ({ row }) => {
					return (
						<Flex justifyContent="flex-start" style={{ gap: 10 }}>
							<div>
								<Icon
									name={prodStatus[row.original.status].icon}
									color={prodStatus[row.original.status].color}
								/>
								{t(mapProductionStatus(row.original))}
							</div>
							<div>
								{row.original.stats && <ProgressRecording size="small" production={row.original} />}
								{orgType === organizationTypes.PRODUCER && (
									<ProgressEdit size="small" production={row.original} />
								)}
							</div>
						</Flex>
					);
				},
			},
			orgType !== organizationTypes.PUBLISHER && {
				id: "productionDate",
				Header: t("productionDueDate", "Due date"),
				collapsing: true,
				accessor: ({ productionDate }) => productionDate?.seconds || 0,
				Cell: ({ row }) => row.original.productionDate?.toDate().toLocaleDateString() || "",
			},
			orgType === organizationTypes.PUBLISHER && {
				id: "deliveryDate",
				Header: t("deliveryDate", "Delivery date"),
				collapsing: true,
				accessor: ({ deliveryDate }) => deliveryDate?.seconds || 0,
				Cell: ({ row }) => row.original.deliveryDate?.toDate().toLocaleDateString() || "",
			},
			{
				id: "actions",
				collapsing: true,
				disableSortBy: true,
				Cell: ({ row }) => {
					return <MyProductionsDropdownMenu production={row.original} />;
				},
			},
		],
		[orgType, t],
	);
}
