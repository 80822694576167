import { useTranslation } from "react-i18next";

import artifactSelectionStatuses from "astrid-firestore/src/api/artifacts/constants/artifactSelectionStatuses";

export default function useArtifactSelectionStatuses() {
	const { t } = useTranslation();

	return {
		[artifactSelectionStatuses.NOT_SELECTED]: {
			text: t("notSelected", "Not selected"),
			color: "grey",
		},
		[artifactSelectionStatuses.SELECTED]: {
			text: t("Selected", "Selected"),
			color: "green",
		},
		[artifactSelectionStatuses.ARCHIVED]: {
			text: t("archived", "Archived"),
			color: "black",
		},
	};
}
