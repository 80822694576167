import db from "astrid-firebase/src/db";
import useDocumentDataOnce from "astrid-firestore/src/hooks/useDocumentDataOnce";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import BiblioSettingsForm from "./components/BiblioSettingsForm";

export default function SuperAdminBiblioSettingsView() {
	const [settings, loading, error] = useDocumentDataOnce(db.collection("integrations").doc("biblio"));

	return (
		<LoadingContext loading={loading} error={error} data={settings}>
			{settings && <BiblioSettingsForm settings={settings} />}
		</LoadingContext>
	);
}
