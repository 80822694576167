import { useTranslation } from "react-i18next";

import articleProductionStatuses from "astrid-firestore/src/api/articles/constants/articleProductionStatuses";

export default function useArticleProductionStatuses() {
	const { t } = useTranslation();

	return {
		[articleProductionStatuses.NOT_STARTED]: {
			text: t("notStarted", "Not started"),
			color: "grey",
		},
		[articleProductionStatuses.ONGOING]: {
			text: t("ongoing", "Ongoing"),
			color: "blue",
		},
		[articleProductionStatuses.COMPLETED]: {
			text: t("completed", "Completed"),
			color: "green",
		},
	};
}
