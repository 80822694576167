import { Route } from "react-router-dom";

import ForwardContext from "../../../layouts/ForwardContext";

// import Publisher from "../../../layouts/Publisher";
import { agreements } from "../../agreements/routes/agreements";
import { expenses, revenues } from "../../costs/routes/costs";
import DistributionSettings from "../../distribution/pages/DistributionSettings";
import { invoices } from "../../invoices/routes/invoices";
import { orders, purchaseOrders, salesOrders } from "../../orders/routes/orders";
import { prices } from "../../prices/routes/prices";

import OrganizationFinance from "../pages/OrganizationFinance/OrganizationFinance";
import OrganizationSettings from "../pages/OrganizationSettings/OrganizationSettings";
import OrganizationSettingsBlurbs from "../pages/OrganizationSettings/OrganizationSettingsBlurbs";
import OrganizationSettingsFinance from "../pages/OrganizationSettings/OrganizationSettingsFinance";
import OrganizationSettingsLanguage from "../pages/OrganizationSettings/OrganizationSettingsLanguage";
import OrganizationSettingsStudio from "../pages/OrganizationSettings/OrganizationSettingsStudio";
import OrganizationSettingsVendors from "../pages/OrganizationSettings/OrganizationSettingsVendors";
import Publisher from "../pages/Publisher";
import Publishers from "../pages/Publishers";

export const publishers = (
	<Route path="publishers" element={<ForwardContext />} handle={{ crumb: ({ t }) => t("publishers", "Publishers") }}>
		<Route index element={<Publishers />} />
		<Route path=":id" element={<Publisher />} handle={{ crumb: ({ id }) => id }} />
	</Route>
);

export const publisherSettings = (
	<Route path="settings" element={<OrganizationSettings />}>
		<Route path="distribution" element={<DistributionSettings />} />
		<Route path="vendors" element={<OrganizationSettingsVendors />} />
		<Route path="language" element={<OrganizationSettingsLanguage />} />
		<Route path="finance" element={<OrganizationSettingsFinance />} />
		<Route path="blurbs" element={<OrganizationSettingsBlurbs />} />
	</Route>
);

export const producerSettings = (
	<Route path="settings" element={<OrganizationSettings />}>
		<Route path="studio" element={<OrganizationSettingsStudio />} />
		<Route path="vendors" element={<OrganizationSettingsVendors />} />
		<Route path="finance" element={<OrganizationSettingsFinance />} />
		<Route path="language" element={<OrganizationSettingsLanguage />} />
	</Route>
);

export const producerFinance = (
	<Route path="finance" element={<OrganizationFinance />}>
		{expenses}
		{orders}
		{purchaseOrders}

		{revenues}
		{salesOrders}

		{invoices}
		{prices}

		{agreements}
	</Route>
);
