import { useState } from "react";

export default function useTable({ data, columns, visibleColumns: initialVisibleColumns }) {
	const [selectedRowIds, setSelectedRowIds] = useState([]);
	const [visibleColumns, setVisibleColumns] = useState(
		initialVisibleColumns || columns.map((column) => column?.id).filter(Boolean),
	);

	const filteredColumns = columns.filter((column) => visibleColumns.includes(column?.id));
	const selectedRows = data.filter(({ id }) => selectedRowIds.includes(id));

	return {
		data,
		columns,
		selectedRows,
		selectedRowIds,
		setSelectedRowIds,
		filteredColumns,
		visibleColumns,
		setVisibleColumns,
	};
}
