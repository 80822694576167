import React from "react";

import CoverImage from "./CoverImage";

export default function CoverImageStack({ data, size = 80, loading, ...props }) {
	return (
		<>
			<div className="image-stack" {...props}>
				<CoverImage thumb rounded data={data} size={size} loading={loading} />
				<CoverImage thumb rounded data={data} size={size} loading={loading} modal />
			</div>
		</>
	);
}
