import getCollectionQuery from "../../utils/getCollectionQuery";

export default function getArticlesQuery(firebase, { publisher, production, imprint, serie, work, type, limit }) {
	return getCollectionQuery(firebase.firestore().collection("articles"), {
		fields: {
			publisher,
			production,
			imprint,
			serie,
			work,
			type,
		},
		limit,
		orderBy: ["name", "asc"],
		softDeletes: true,
	});
}
