import { Input } from "semantic-ui-react";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { db } from "astrid-firebase";
import { useCollectionData, useLoadingValues } from "astrid-firestore/src/hooks";

import Flex from "../../../../components/Flex/Flex";
import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import BiblioTasksView from "../../views/BiblioTasksView/BiblioTasksView";

import BiblioTasksMenu from "./components/BiblioTasksMenu";

export default function BiblioTasksLayout({ uncompletedTaskCount, children }) {
	const { t } = useTranslation();

	const [search, setSearch] = useState("");

	const [tasks, loading, error] = useLoadingValues(
		useCollectionData(
			search &&
				db.collection("tasks/queue/tasks").where("isbns", "array-contains", search).orderBy("created", "desc"),
		),
		useCollectionData(
			search &&
				db.collection("tasks/queue/tasks").where("production.id", "==", search).orderBy("created", "desc"),
		),
	);

	const flatTasks = tasks?.flat().filter(Boolean);

	return (
		<>
			<Flex justify="flex-start">
				<BiblioTasksMenu uncompletedTaskCount={uncompletedTaskCount} />

				<Input
					value={search}
					placeholder={t("search", "Search")}
					onChange={(e, { value }) => setSearch(value)}
				/>
			</Flex>

			<LoadingContext loading={loading} error={error}>
				{flatTasks?.length > 0 ? <BiblioTasksView tasks={flatTasks} /> : children}
			</LoadingContext>
		</>
	);
}
