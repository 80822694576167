import React from "react";
import { useOutletContext } from "react-router-dom";

import PublisherBlurbs from "../../../../components/publisher/PublisherBlurbs";

export default function OrganizationSettingsBlurbs() {
	const { organization } = useOutletContext();

	return (
		<PublisherBlurbs
			template
			pid={organization.id}
			publisher={organization}
			singleLanguage={(!(organization.languageOptions || []).length && organization.languageDefault) || false}
		/>
	);
}
