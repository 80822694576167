import React from "react";

import Button from "astrid-components/lib/components/Inputs/Button";

export default function ArtifactAudioPlayerTool({ tool, activeTool, onChange, children }) {
	const onMouseDown = ({ button }) => {
		if (button === 0) {
			onChange?.(tool);
		}
	};

	return (
		<Button
			size="small"
			color="primary"
			active={tool === activeTool}
			onMouseDown={onMouseDown}
			onTouchStart={onMouseDown}
		>
			{children}
		</Button>
	);
}
