import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import artifactMarkerTypes from "astrid-firestore/src/api/artifacts/constants/artifactMarkerTypes";

export default function useArtifactAudioPlayerMarkers({ markers, files }) {
	const { t } = useTranslation();

	return useMemo(() => {
		const markerProps = {
			[artifactMarkerTypes.CHAPTER]: (chapterNumber) => ({
				color: "positive",
				text: `${t("chapter", "Chapter")} ${chapterNumber}`,
			}),
			[artifactMarkerTypes.SPLIT]: () => ({
				color: "primary",
				text: t("splitPoint", "Split point"),
			}),
		};

		let chapterNumber = 1;

		return markers
			.map((marker) => {
				const props = markerProps[marker.type](chapterNumber);

				if (marker.type === artifactMarkerTypes.CHAPTER) {
					chapterNumber++;
				}

				return { ...marker, ...props };
			})
			.concat(
				files.map((file) => ({
					id: file.id,
					position: file.start,
					color: "text",
					text: file.name,
					safe: true,
				})),
			)
			.sort((a, b) => a.position - b.position);
	}, [files, markers, t]);
}
