import uniqid from "uniqid";

import artifactMarkerTypes from "./constants/artifactMarkerTypes";
import flattenArtifactSilences from "./flattenArtifactSilences";
import sortArtifactFiles from "./sortArtifactFiles";

const TEN_HOURS_IN_MINUTES = 60 * 10;

export default function prepareArtifactMarkers({ artifact, files, maxFileDurationInMinutes }) {
	if (!artifact.format) {
		return [];
	}

	const { byteRate, blockAlign } = artifact.format;

	const sortedFiles = sortArtifactFiles(files, artifact.files);
	const silences = flattenArtifactSilences(sortedFiles);

	const totalDuration = sortedFiles.reduce((acc, { duration }) => acc + duration * 1000, 0);

	const fileMap = sortedFiles.reduce((acc, file) => ({ ...acc, [file.id]: file }), {});
	const maxDuration = 1000 * 60 * (maxFileDurationInMinutes || TEN_HOURS_IN_MINUTES);

	return artifact.markers
		.filter((marker) => fileMap[marker.fileId])
		.map((marker, index) => ({
			...marker,
			index,
			position: fileMap[marker.fileId].start + marker.position,
		}))
		.sort((a, b) => a.position - b.position)
		.flatMap(addAdditionalSplitPoints({ silences, totalDuration, maxDuration }))
		.flatMap(addAdditionalSplitPoints({ silences, totalDuration, maxDuration }))
		.map((marker, index, array) => {
			// The number of bytes to read from the file
			const duration = getDuration(marker, index, array, totalDuration);
			const bytesFromDuration = byteRate * (duration / 1000);

			// Round down to the nearest block align (e.g. 2 bytes for 16-bit audio)
			const bytes = Math.floor(bytesFromDuration / blockAlign) * blockAlign;

			return { ...marker, blockAlign, byteRate, bytes };
		});
}

function addAdditionalSplitPoints({ silences, totalDuration, maxDuration }) {
	return (marker, index, array) => {
		// If the duration is longer than the max duration, split into multiple markers
		const duration = getDuration(marker, index, array, totalDuration);
		const numberOfMarkers = Math.ceil(duration / maxDuration);
		const durationPerMarker = duration / numberOfMarkers;

		return Array.from({ length: numberOfMarkers }, (_, i) => {
			const position = marker.position + i * durationPerMarker;
			const maxDistanceFromPosition = durationPerMarker / 2;

			// If this is the first marker, return it as is
			if (i === 0) {
				return {
					...marker,
					safe: isPositionSafe(marker.position, silences),
				};
			}

			const silencePosition = getClosestSilencePosition(position, silences, maxDistanceFromPosition);

			// Otherwise, return a new marker with the split position
			return {
				...marker,
				id: `${marker.id}-${uniqid()}`,
				type: artifactMarkerTypes.SPLIT,
				position: silencePosition,
				safe: position !== silencePosition,
			};
		});
	};
}

function getDuration(marker, index, array, total) {
	// Calculate the duration of the marker
	// If the marker is the last one, set the duration to the end of the file
	const nextPosition = array[index + 1]?.position || total;
	return nextPosition - marker.position;
}

function getSilenceMidpoint(silence) {
	return Math.floor(silence.end - (silence.end - silence.start) / 2);
}

function getClosestSilencePosition(position, silences, distance) {
	// Find the silence closest to the position
	const silence = silences
		.filter((silence) => Math.abs(getSilenceMidpoint(silence) - position) <= distance)
		.sort((a, b) => Math.abs(getSilenceMidpoint(a) - position) - Math.abs(getSilenceMidpoint(b) - position))[0];

	// If no silence is found, return the original position
	if (!silence) {
		return position;
	}

	// Otherwise, return the midpoint of the silence
	return getSilenceMidpoint(silence);
}

function isPositionSafe(position, silences) {
	const silence = silences.find((silence) => position >= silence.start && position <= silence.end);

	return !!silence;
}
