import React from "react";
import { Outlet, useOutletContext, useParams } from "react-router-dom";

import { db } from "astrid-firebase";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import featureFlags from "../../../authorization/constants/featureFlags";

import ArtifactView from "../../views/ArtifactView/ArtifactView";

export default function Artifact() {
	const params = useParams();
	const { work, article, production } = useOutletContext();

	const [artifact, loading, error] = useDocumentData(db.collection("artifacts").doc(params.artifact));

	return (
		<LoadingContext
			data={artifact}
			loading={loading}
			error={error}
			showDeleted
			featureFlag={featureFlags.FLATTENING}
		>
			<ArtifactView work={work} article={article} artifact={artifact} production={production}>
				<Outlet context={{ work, article, artifact, production }} />
			</ArtifactView>
		</LoadingContext>
	);
}
