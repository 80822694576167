import { productionCategories } from "astrid-config/src/productionCategories/productionCategories";

import FirestoreDocument from "../../../types/FirestoreDocument";
import {
	array,
	enumArray,
	enumValues,
	language,
	nullable,
	record,
	ref,
	string,
	timestamp,
} from "../../../types/_types";

import { ScriptRef } from "../../scripts/types/Script";
import { UserRef } from "../../users/types/User";
import { WorkRef } from "../../works/types/WorkRef";

import productionStatuses from "../constants/productionStatuses";
import productionTypes from "../constants/productionTypes";

export const productionType = nullable(enumValues(productionTypes));
export const productionStatus = enumValues(productionStatuses);
export const productionCategory = enumArray(productionCategories);

export const Production = FirestoreDocument.extend({
	title: string(2),
	productionType: productionType,
	work: nullable(WorkRef),
	articleIds: array(string()),
	status: productionStatus,
	producer: string(),
	producerName: string(),
	publisher: string(),
	publisherName: string(),
	language: language(),
	category: nullable(productionCategory),
	scripts: record(string(), ScriptRef).default({}),
	publisherManagers: array(UserRef),
	publisherManagerIds: array(string()),
	tags: array(string()),
	productionTags: array(string()),
	productionDate: nullable(timestamp()),
});

export const ProductionRef = ref(Production, {
	title: true,
	productionType: true,
});
