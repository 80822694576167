import { Input } from "semantic-ui-react";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInstantSearch, useSearchBox } from "react-instantsearch";

import renderChildren from "../../../../utils/renderChildren";

export default function InstantSearchBox({ children, ...props }) {
	const { t } = useTranslation();

	const { indexUiState } = useInstantSearch();

	const { query, refine, clear } = useSearchBox();

	const [searchQuery, setSearchQuery] = useState(indexUiState?.query || "");

	useEffect(() => {
		if (query !== searchQuery) {
			refine(searchQuery);
		}
	}, [query, searchQuery, refine]);

	if (children) {
		return renderChildren(children, {
			clear,
			searchQuery,
			setSearchQuery,
		});
	}

	return (
		<Input
			fluid
			clearable
			placeholder={t("search", "Search") + "..."}
			onChange={(e, { value }) => setSearchQuery(value)}
			value={searchQuery}
			{...props}
		/>
	);
}
