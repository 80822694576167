import { useDebounce } from "use-debounce";

import React, { useMemo } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";

export default function ArtifactAudioPlayerTimelineSilences({ silences }) {
	const [scope] = useDebounce(Timeline.useScope(), 300);

	const filteredSilences = useMemo(
		() =>
			silences?.filter((silence) => {
				return silence.end > scope.start && silence.start < scope.end;
			}),
		[silences, scope.start, scope.end],
	);

	return filteredSilences?.map((silence) => {
		return (
			<Timeline.Range
				key={silence.start}
				color="secondary"
				position={silence.start}
				length={silence.end - silence.start}
			/>
		);
	});
}
