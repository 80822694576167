import equal from "fast-deep-equal";

import { Icon } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import { firebase } from "astrid-firebase";
import articleTypes from "astrid-firestore/src/api/articles/constants/articleTypes";
import linkedArticleTypes from "astrid-firestore/src/api/articles/constants/linkedArticleTypes";
import { Article } from "astrid-firestore/src/api/articles/types/Article";
import updateArticle from "astrid-firestore/src/api/articles/updateArticle";

import Flex from "../../../../components/Flex/Flex";
import FlexRow from "../../../../components/Flex/FlexRow";
import Button from "../../../ui/components/Buttons/Button";
import Message from "../../../ui/components/Messages/Message";

import AdminCard from "../../../admin/components/AdminCard/AdminCard";
import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";
import SyncWorkMetaDataButton from "../../../works/components/SyncWorkMetadataForm/SyncWorkMetaDataButton";

import useArticleAbridgedOptions from "../../hooks/useArticleAbridgedOptions";

import ArticleTags from "../ArticleTags/ArticleTags";
import ArticlesInstantSearchSelect from "../ArticlesInstantSearchSelect/ArticlesInstantSearchSelect";

import ArticleMetaDataContributors from "./components/ArticleMetaDataContributors";
import ArticleMetaDataImprintSelect from "./components/ArticleMetaDataImprintSelect";
import ArticleMetaDataLabel from "./components/ArticleMetaDataLabel";
import ArticleMetaDataSeasonSelect from "./components/ArticleMetaDataSeasonSelect";
import ArticleMetaDataSerieNumberSelect from "./components/ArticleMetaDataSerieNumberSelect";
import ArticleMetaDataSerieSelect from "./components/ArticleMetaDataSerieSelect";
import ArticleMetaDataThemaCodes from "./components/ArticleMetaDataThemaCodes";

export default function ArticleMetaDataForm({ work, article, publisher, ...props }) {
	const { t } = useTranslation();

	const { form, watch, formState, onSubmit } = useSimpleForm({
		schema: Article,
		values: {
			ref: article.ref,
			abridged: article.abridged || false,
			additionalLanguages: article.additionalLanguages || null,
			contributors: article.contributors || [],
			duration: article.duration || 0,
			imprint: article.imprint || null,
			isbn: article.isbn || null,
			language: article.language || null,
			linkedArticle: article.linkedArticle || null,
			name: article.name || null,
			subtitle: article.subtitle || null,
			originalLanguage: article.originalLanguage || null,
			originalName: article.originalName || null,
			serie: article.serie || null,
			season: article.season || null,
			serieNumber: article.serieNumber || null,
			synopsis: article.synopsis || null,
			tags: article.tags || [],
			themaCodes: article.themaCodes || null,
		},
		onSubmit: (data) => {
			return updateArticle(firebase, data);
		},
		...props,
	});

	const language = watch("language");

	const abridgedOptions = useArticleAbridgedOptions();

	return (
		<AdminCard
			header={t("metaData", "Meta data")}
			extra={
				<FlexRow style={{ gap: 10 }}>
					<Button
						primary
						disabled={formState.isSubmitting || !formState.isDirty}
						loading={formState.isSubmitting}
						onClick={onSubmit}
					>
						{t("save", "Save")}
					</Button>

					{work && (
						<SyncWorkMetaDataButton
							work={work}
							articles={[article]}
							text={t("syncFromWork", "Sync from work")}
						/>
					)}
				</FlexRow>
			}
		>
			<Form form={form}>
				<Form.Group widths="equal">
					<Form.Input name="isbn" label={t("isbn", "ISBN")} />

					<Form.Input
						name="name"
						label={
							<ArticleMetaDataLabel
								text={t("title", "Title")}
								condition={work && !equal(article.name, work.name)}
							/>
						}
					/>

					<Form.Input
						name="subtitle"
						label={
							<ArticleMetaDataLabel
								text={t("subtitle", "Subtitle")}
								condition={work && !equal(article.subtitle, work.subtitle)}
							/>
						}
					/>

					<Form.Input
						name="originalName"
						label={
							<ArticleMetaDataLabel
								text={t("titleOriginal", "Original title")}
								condition={work && !equal(article.originalName, work.originalName)}
							/>
						}
					/>
				</Form.Group>

				<Form.Group widths="equal">
					<ArticleMetaDataImprintSelect work={work} article={article} />

					<ArticlesInstantSearchSelect
						name="linkedArticle"
						label={t("linkedArticle", "Linked article")}
						fluid
						allowEmpty
						clearable
						publisher={publisher}
						disabled={article.type === articleTypes.EBOOK}
						type={linkedArticleTypes[article.type]}
					/>

					<Form.Select
						name="abridged"
						label={
							<ArticleMetaDataLabel
								condition={work && !equal(article.abridged, work.abridged)}
								text={t("abridged", "Abridged")}
							/>
						}
						options={abridgedOptions}
					/>
				</Form.Group>

				<Form.TextArea
					name="synopsis"
					label={
						<ArticleMetaDataLabel
							condition={work && !equal(article.synopsis, work.synopsis)}
							text={t("synopsis", "Description")}
						/>
					}
					style={{
						fieldSizing: "content",
					}}
				/>

				<Form.Group widths="equal">
					<ArticleMetaDataSerieSelect work={work} article={article} />
					<ArticleMetaDataSeasonSelect work={work} article={article} />
					<ArticleMetaDataSerieNumberSelect work={work} article={article} />
				</Form.Group>

				<Form.Group widths="equal">
					<Form.LanguageSelect
						name="language"
						label={
							<ArticleMetaDataLabel
								condition={work && article.language !== work.language}
								text={t("language", "Language")}
							/>
						}
						limitedOptions={publisher.languageOptions}
						allowEmpty
					/>

					<Form.LanguageSelect
						name="originalLanguage"
						label={
							<ArticleMetaDataLabel
								condition={work && article.originalLanguage !== work.originalLanguage}
								text={t("originalLanguage", "Original language")}
							/>
						}
						allowEmpty
					/>

					<Form.LanguageSelect
						name="additionalLanguages"
						label={
							<ArticleMetaDataLabel
								condition={work && !equal(article.additionalLanguages, work.additionalLanguages)}
								text={t("otherLanguages", "Other occuring languages")}
							/>
						}
						filterOptions={(option) => option.value !== language}
						multiple
					/>
				</Form.Group>

				<Form.Group widths="equal">
					<Form.DurationInput
						fluid
						name="duration"
						label={t("duration", "Duration") + " (hhh:mm:ss)"}
						disabled={article.type === articleTypes.EBOOK}
					/>

					<ArticleMetaDataThemaCodes work={work} article={article} />

					<Form.Field name="tags">
						<ArticleTags
							article={article}
							label={
								<ArticleMetaDataLabel
									condition={work && !equal(article.tags, work.tags)}
									text={t("tags", "Tags")}
								/>
							}
						/>
					</Form.Field>
				</Form.Group>

				<ArticleMetaDataContributors work={work} article={article} />

				{work && (
					<Message>
						<Flex justifyContent="flex-start">
							<Icon size="small" color="blue" name="asterisk" />
							{t("dataDifferesFromWork", "Data differes from work")}
						</Flex>
					</Message>
				)}

				<Form.Blocker />
			</Form>
		</AdminCard>
	);
}
