import React from "react";
import { useTranslation } from "react-i18next";

import Flex from "../../../../components/Flex/Flex";
import Label from "../../../ui/components/Label/Label";

import ArtifactProductionStatusLabel from "./ArtifactProductionStatusLabel";
import ArtifactSelectionStatusLabel from "./ArtifactSelectionStatusLabel";
import ArtifactValidationStatusLabel from "./ArtifactValidationStatusLabel";

export default function ArtifactStatusLabels({ artifact, article, production }) {
	const { t } = useTranslation();

	if (artifact.deleted) {
		return <Label color="red">{t("deleted", "Deleted")}</Label>;
	}

	return (
		<div>
			<Flex gap={5}>
				<ArtifactValidationStatusLabel artifact={artifact} />

				{article && <ArtifactSelectionStatusLabel artifact={artifact} article={article} />}

				{production && <ArtifactProductionStatusLabel artifact={artifact} />}
			</Flex>
		</div>
	);
}
