import Form from "../../../forms/components/Form/Form";

import useArticleTypeTranslations from "../../hooks/useArticleTypeTranslations";

export default function ArticlesInstantSearchSelect({ publisher, type, work, notWork, ...props }) {
	const articleTypeTranslations = useArticleTypeTranslations();

	const filters = [
		type && `type:${type}`,
		work && `work.id:${work.id}`,
		notWork && `NOT work.id:${notWork.id}`,
		publisher && `publisher.id:${publisher?.id}`,
	]
		.filter(Boolean)
		.join(" AND ");

	return (
		<Form.InstantSearchSelect
			indexName="articles"
			filters={filters}
			getOption={(article) => {
				const nameAndType = `[${article.isbn}] ${article.name} (${articleTypeTranslations[article.type]})`;

				return {
					key: article.id,
					value: article.id,
					text: nameAndType,
					data: article,
				};
			}}
			{...props}
		/>
	);
}
