import { useState } from "react";

import List from "astrid-components/lib/components/Data/List";

import useArtifactAudioPlayerTimeline from "../hooks/useArtifactAudioPlayerTimeline";

import ArtifactAudioPlayerMarkerListItem from "./ArtifactAudioPlayerMarkerListItem";

export default function ArtifactAudioPlayerMarkerList({ markers, onRemoveMarker }) {
	const { moveToPosition } = useArtifactAudioPlayerTimeline();

	const [activeId, setActiveId] = useState();

	const item = (index) => {
		const marker = markers[index];

		return (
			<ArtifactAudioPlayerMarkerListItem
				first={index === 0}
				last={index === markers.length - 1}
				marker={marker}
				active={activeId === marker.id}
				onClick={() => {
					setActiveId(marker.id);
					moveToPosition(marker.position);
				}}
				onRemove={() => onRemoveMarker({ index, marker })}
			/>
		);
	};

	return <List.Variable itemContent={item} totalCount={markers.length} />;
}
