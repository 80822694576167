import useDetectScroll from "@smakss/react-scroll-direction";

import { Image, Menu, Segment } from "semantic-ui-react";

import { Link } from "react-router-dom";
import useWindowSize from "react-use/lib/useWindowSize";

import Flex from "../../../../components/Flex/Flex";
import MenuItems from "../../../ui/components/Menu/components/MenuItems";

import featureFlags from "../../../authorization/constants/featureFlags";
import useFeatureFlag from "../../../authorization/hooks/useFeatureFlag";
import useHasRight from "../../../authorization/hooks/useHasRight";
import QuickSearch from "../../../productions/components/QuickSearch/QuickSearch";
import QuickSearchBeta from "../../../productions/components/QuickSearch/QuickSearchBeta";

import DiscussionPortal from "./components/DiscussionPortal";
import LanguageMenu from "./components/LanguageMenu";
import OrganizationMenu from "./components/OrganizationsMenu";
import SecondaryMenu from "./components/SecondaryMenu";
import useMainMenuOptions from "./hooks/useMainMenuOptions";

const height = 55;

export default function MainMenu({ organization }) {
	const hasDiscussionFeature = useFeatureFlag(featureFlags.DISCUSSION);
	const hasQuickSearchFeature = useFeatureFlag(featureFlags.QUICK_SEARCH);

	const hasRight = useHasRight();
	const options = useMainMenuOptions({ organization });

	const { width } = useWindowSize();
	const { scrollPosition } = useDetectScroll();

	const desktop = width > 600;
	const transition = desktop ? (scrollPosition.top > height ? 1 : 0) : 0;

	const segmentStyle = {
		position: "sticky",
		top: -height,
		left: 0,
		right: 0,
		zIndex: 999,
		height: 96,
		padding: 0,
		borderColor: "rgba(0, 0, 0, 0.1)",
	};

	const iconStyle = {
		transform: transition ? "translateY(48px) scale(0.8)" : "translateY(0) scale(1)",
		transition: transition ? "transform 0.4s ease" : "transform 0.3s ease",
	};

	const menuStyle = {
		margin: 0,
		padding: "0 15px 3px 8px",
		transform: transition ? "translateX(40px)" : "translateX(0)",
		transition: transition ? "transform 0.3s ease" : "transform 0.4s ease",
		borderBottomWidth: 0,
		borderTop: "1px solid rgba(0, 0, 0, 0.05)",
		overflowX: "auto",
		scrollbarWidth: "none",
	};

	const divider = <Menu.Item style={{ opacity: 0.2, fontSize: 18 }}>/</Menu.Item>;

	return (
		<Segment attached style={segmentStyle}>
			<Flex style={{ height, padding: "0 15px" }}>
				<Menu text style={{ margin: 0 }}>
					<Menu.Item as={Link} to="/" style={iconStyle}>
						<Image src="/img/astrid-icon.svg" size="small" style={{ height: 22, width: "auto" }} />
					</Menu.Item>

					{divider}

					<OrganizationMenu organization={organization} />

					{hasRight("productions.quickSearch") && (
						<>
							{divider}

							<Menu.Item as="label" style={{ cursor: "pointer", margin: 0, width: desktop ? 500 : 140 }}>
								{hasQuickSearchFeature && <QuickSearchBeta organization={organization} />}
								{!hasQuickSearchFeature && <QuickSearch organization={organization} />}
							</Menu.Item>
						</>
					)}
				</Menu>

				<Flex gap={15} justifyContent="flex-end">
					<LanguageMenu />
					<SecondaryMenu organization={organization} />
					{hasDiscussionFeature && <DiscussionPortal />}
				</Flex>
			</Flex>

			{options?.length > 0 && (
				<Menu fluid secondary pointing style={menuStyle}>
					<MenuItems options={options} />
				</Menu>
			)}
		</Segment>
	);
}
