import articleDeliveryStatuses from "astrid-firestore/src/api/articles/constants/articleDeliveryStatuses";
import { ArticleChannel } from "astrid-firestore/src/api/articles/types/ArticleChannel";
import { getCollectionData } from "astrid-firestore/src/helpers";
import { formatDate } from "astrid-web/src/helpers/date";

import distributionPriceTypes from "../../src/api/distribution/constants/distributionPriceTypes";
import getArticlePresets from "../../src/api/distribution/utils/getArticlePresets";

const CHANNEL_NAME_MAPPING = {
	bookbeat: "Bookbeat",
	storytel: "Storytel",
	storytelFilesDotCom: "Storytel",
	storytelOld: "Storytel",
	litres: "LitRes",
	nextory: "Nextory",
	nextory_audio: "Nextory",
	nextory_ebook: "Nextory",
	overdrive: "Overdrive",
	ellibs: "Ellibs",
	elisa: "Elisa",
	fabel: "Fabel",
	audibleDE: "Audible DE",
	audibleUK: "Audible UK",
	bookmate: "Bookmate",
	bokbasen: "Bokbasen",
	bokbasenApi: "Bokbasen",
	axiellFtp: "Axiell",
	publit: "Publit",
	bonnierWav: "Bonnier",
	bonnierMp3: "Bonnier",
	polaris: "Polaris",
	nfg: "NFG",
	bookwire: "Bookwire",
	clipsource: "Clip source",
	kungligaBiblioteket_audio: "Kungliga Biblioteket",
	kungligaBiblioteket_ebook: "Kungliga Biblioteket",
	/*
	// Removed - do we need to handle history?
	storytool,
	storytoolWav,
	storytoolMp3,
	Landsbókasafn
	optimal
	bod
	*/
};

function prioritizeChannel(channel, releaseData) {
	if (channel === "storytel") {
		if (releaseData.storytelFilesDotCom) {
			// if we have storytelFilesDotCom history, skip old stuff.
			return false;
		}
	}

	if (channel === "storytelOld") {
		if (releaseData.storytelFilesDotCom) {
			// if we have storytelFilesDotCom history, skip old stuff.
			return false;
		} else if (releaseData.storytel) {
			// prioritzie storytel over storytelOld
			return false;
		}
	}

	if (channel === "nextory") {
		if (releaseData.nextory_audio || releaseData.nextory_ebook) {
			// if we have nextory_audio/ebook history, skip old stuff.
			return false;
		}
	}

	return true;
}

function channelSupportsMetadata(channel) {
	return channel.enabledTemplates?.length > 0;
}

function mapArtifactReleaseData({ production, articleType }) {
	return Object.entries(production.master?.[articleType]?.exports || {}).reduce((acc, [_, exportData]) => {
		return {
			...acc,
			...Object.entries(exportData.distributor).reduce((acc, [channel, distributorData]) => {
				return {
					...acc,
					[channel]: { sent: distributorData.sent },
				};
			}, {}),
		};
	}, {});
}

function hasSinglePrice({ metadata }) {
	return metadata.priceUnitSale || metadata.priceOCOU;
}

function hasPayPerLoanPrice({ metadata }) {
	return metadata.pricePayPerLoan || metadata.priceCPC;
}

function mapMetadataReleaseData({ release }) {
	return Object.entries(release || {}).reduce((acc, [channel, metadata]) => {
		return {
			...acc,
			[channel]: {
				sent: metadata.metaSent || null,
				announcementDate: metadata.announcementDate || null,
				releaseDate: metadata.date || null,
				takedownDate: metadata.takedown || null,
				countries: metadata.countriesIncluded || (metadata.world ? null : metadata.markets),
				prices:
					hasSinglePrice({ metadata }) || hasPayPerLoanPrice({ metadata })
						? [
								hasSinglePrice({ metadata }) && {
									amount: Number(metadata.priceUnitSale || metadata.priceOCOU),
									currency: metadata.priceUnitSaleCurrency || metadata.priceOCOUCurrency || "SEK",
									type: distributionPriceTypes.SINGLE,
								},
								hasPayPerLoanPrice({ metadata }) && {
									amount: Number(metadata.pricePayPerLoan || metadata.priceCPC),
									currency: metadata.pricePayPerLoanCurrency || metadata.priceCPCCurrency || "SEK",
									type: distributionPriceTypes.PAY_PER_LOAN,
								},
						  ].filter(Boolean)
						: null,
			},
		};
	}, {});
}

function combineReleaseData({ meta, artifact }) {
	const combined = {};

	const platforms = new Set([...Object.keys(meta || {}), ...Object.keys(artifact)]);

	platforms.forEach((platform) => {
		combined[platform] = {
			meta: meta[platform] || {},
			artifact: artifact[platform] || {},
		};
	});

	return combined;
}

function getReleaseData({ production, articleType }) {
	if (articleType === "total") {
		const meta = mapMetadataReleaseData({ release: production.release });
		const artifact = mapArtifactReleaseData({ production, articleType });
		return combineReleaseData({ meta, artifact });
	} else if (articleType.includes("part")) {
		const partNumber = articleType.split("_")[1];
		const meta = mapMetadataReleaseData({
			release: production.deliveryParts[partNumber].release,
		});
		const artifact = mapArtifactReleaseData({ production, articleType });
		return combineReleaseData({ meta, artifact });
	} else if (articleType === "ebook") {
		const meta = mapMetadataReleaseData({ release: production.deliveryEbook.release });
		const artifact = meta;
		return combineReleaseData({ meta, artifact });
	} else {
		throw new Error(`Unknown article type when fetching release data: ${articleType}`);
	}
}

export default async function createChannels({ api, env }, { article, fullPublisherDoc, production, articleType }) {
	const distributionChannels = api.convertFromLiveToStage
		? await getCollectionData(api.stage.db.collection("distributionChannels"))
		: await getCollectionData(api[env].db.collection("distributionChannels"));
	const releaseData = getReleaseData({ production, articleType });

	const mappedToNewChannelNames = Object.entries(releaseData)
		.filter(([channelKey, _]) => prioritizeChannel(channelKey, releaseData))
		.map(([channelKey, releaseData]) => {
			const channelName = CHANNEL_NAME_MAPPING[channelKey];
			if (!channelName) {
				throw new Error(`Unknown channel: ${channelKey}`);
			}
			const { meta, artifact } = releaseData;
			return {
				channelName,
				meta,
				artifact,
			};
		});

	const presets = getArticlePresets({ article, publisher: fullPublisherDoc }).map((preset) => {
		return {
			channelId: preset.channel.id,
			channelName: preset.channel.name,
			meta: null, // no send since they didnt exist in the original data
			artifact: null, // no send since they didnt exist in the original data
			targetData: preset.targetData,
		};
	});

	const merged = [
		...mappedToNewChannelNames,
		...presets.filter(
			(preset) => !mappedToNewChannelNames.some((mappedItem) => mappedItem.channelName === preset.channelName),
		),
	];

	return merged.reduce((acc, { channelName, meta, artifact }) => {
		const channel = distributionChannels.find((channel) => channel.name === channelName);
		const channelId = channel?.id;
		const preset = presets.find((preset) => preset.channelId === channelId)?.targetData;

		if (!channelId) {
			// means we have not created the distribution channel yet.
			console.warn(`Channel ${channelName} not found in distributionChannels`);
			return acc;
		}

		const supportsMetadata = channelSupportsMetadata(channel);

		return {
			...acc,
			[channelId]: ArticleChannel.parse({
				delivery: {
					artifact: {
						status: artifact?.sent?.seconds
							? articleDeliveryStatuses.DELIVERY_COMPLETED
							: api.isFinalizing
							? articleDeliveryStatuses.DELIVERY_NEEDED
							: articleDeliveryStatuses.DELIVERY_NOT_STARTED,
						sent: artifact?.sent?.seconds ? api.Timestamp(artifact.sent) : null,
					},
					metadata: {
						status:
							supportsMetadata && meta?.sent?.seconds
								? articleDeliveryStatuses.DELIVERY_COMPLETED
								: supportsMetadata
								? api.isFinalizing
									? articleDeliveryStatuses.DELIVERY_NEEDED
									: articleDeliveryStatuses.DELIVERY_NOT_STARTED
								: articleDeliveryStatuses.DELIVERY_UNSUPPORTED,
						sent: meta?.sent?.seconds ? api.Timestamp(meta.sent) : null,
					},
				},
				announcementDate: meta?.announcementDate
					? formatDate(api.Timestamp(meta?.announcementDate)?.toDate())
					: null,
				releaseDate: meta?.releaseDate ? formatDate(api.Timestamp(meta.releaseDate)?.toDate()) : null,
				takedownDate: meta?.takedownDate ? formatDate(api.Timestamp(meta.takedownDate)?.toDate()) : null,
				countries: meta?.countries || preset?.countries || ["WORLD"],
				prices: meta?.prices || preset?.prices || [],
			}),
		};
	}, {});
}
