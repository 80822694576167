import firebase from "firebase/app";
import uniqid from "uniqid";

import artifactValidationStatuses from "astrid-firestore/src/api/artifacts/constants/artifactValidationStatuses";
import updateArtifact from "astrid-firestore/src/api/artifacts/updateArtifact";
import useBucket from "astrid-hooks/src/useBucket";

import { useProfile } from "../../../../authentication/state/profile";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";

export default function useArtifactManagerForm({ artifact, files }) {
	const user = useProfile();

	const defaultValues = {
		fileIds: artifact.files,
		markers: artifact.markers.map((marker) => ({
			...marker,
			id: String(marker.id) || uniqid(),
		})),
	};

	const { deleteFiles } = useBucket("earselect-artifacts");

	const { form, formState, reset, onSubmit } = useSimpleForm({
		defaultValues,
		onSubmit: async ({ fileIds, markers }) => {
			const filesToDelete = files.filter((file) => !fileIds.includes(file.id));

			await updateArtifact(
				firebase,
				{
					ref: artifact.ref,
					files: fileIds.map((id) => files.find((file) => file.id === id)),
					status: artifactValidationStatuses.PENDING,
					article: artifact.article,
					markers: markers.filter((marker) => fileIds.includes(marker.fileId)),
					updatedBy: user,
				},
				filesToDelete,
			);

			if (filesToDelete?.length) {
				await deleteFiles(filesToDelete);
			}
		},
	});

	const onReset = () => {
		reset(defaultValues);
	};

	return {
		form,
		formState,
		onSubmit,
		onReset,
	};
}
