import { Button, Checkbox, Message, Modal } from "semantic-ui-react";

import React, { useState } from "react";
import { Link } from "react-router-dom";

import { db, firebase } from "astrid-firebase";
import storage from "astrid-firebase/src/storage";
import { migrateProductionToFlattening } from "astrid-firestore/scripts/migrateProductionToFlattening/migrateProductionToFlattening";
import revertMigratedProduction from "astrid-firestore/scripts/migrateProductionToFlattening/revertMigratedProduction";
import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";
import { isLive } from "astrid-helpers/src/env";
import stopPropagation from "astrid-helpers/src/stopPropagation";

import featureFlags from "../../../features/authorization/constants/featureFlags";
import useFeatureFlag from "../../../features/authorization/hooks/useFeatureFlag";
import useCurrentOrganization from "../../../features/organizations/hooks/useCurrentOrganization";
import PrimaryButton from "../../../features/ui/components/Buttons/PrimaryButton";
import SecondaryButton from "../../../features/ui/components/Buttons/SecondaryButton";
import ErrorMessage from "../../../features/ui/components/Messages/ErrorMessage";
import useLoadingFunction from "../../../features/ui/hooks/useLoadingFunction";

const env = isLive ? "live" : "stage";
export const migrationApi = {
	admin: firebase,
	[env]: {
		db,
		getStorageMetadata: async (bucket, file) => {
			const metadata = await storage.refFromURL(`gs://${bucket}`).child(file).getMetadata();
			return [metadata];
		},
		getStorageJsonFile: async (bucket, file) => {
			const url = await storage.refFromURL(`gs://${bucket}`).child(file).getDownloadURL();
			const response = await fetch(url);
			if (!response.ok) {
				throw new Error(`Failed to download file: ${response.statusText}`);
			}
			const arrayBuffer = await response.arrayBuffer();
			const jsonString = new TextDecoder("utf-8").decode(arrayBuffer);
			return JSON.parse(jsonString);
		},
	},
	Timestamp: (ts) => {
		return new firebase.firestore.Timestamp(ts.seconds, ts.nanoseconds);
	},
};

function reduceDocuments({ documents, created = true }) {
	return documents.reduce((acc, doc) => {
		let groupKey = doc.collection;
		if (/^artifacts\/[^/]+\/files$/.test(doc.collection)) {
			groupKey = "artifact-files";
		}
		if (!acc[groupKey]) {
			acc[groupKey] = [];
		}
		acc[groupKey].push({
			id: doc.id || doc.data.id,
			linkText:
				created && groupKey !== "artifact-files"
					? groupKey === "articles"
						? `${doc.data.name} - ${doc.data.type}`
						: `${doc.collection}/${doc.data.id}`
					: null,
		});
		return acc;
	}, {});
}

function MigrationMessages({ messages }) {
	return (
		<>
			{messages.map((message) => {
				return (
					<Message
						color={message.created ? "green" : "blue"}
						key={message.id}
						success={message.success}
						header={message.header}
						content={message.message}
					/>
				);
			})}
		</>
	);
}

function RevertMigration({ production, setMessages, onClose }) {
	const [revertMigration, revertLoading, error] = useLoadingFunction(async () => {
		const deletedDocuments = await revertMigratedProduction({ api: migrationApi, env }, { production });
		const messagesByCollection = reduceDocuments({ documents: deletedDocuments, created: false });
		setMessages(
			Object.entries(messagesByCollection).map(([collection, documents]) => ({
				id: collection,
				header: `Deleted ${documents.length} ${collection}`,
				deleted: true,
			})),
		);
	});

	return (
		<>
			<Modal.Content>
				<p>{`Are you sure you wish to revert the migration of ${production.title}?`}</p>
				{error && <ErrorMessage error={error} />}
			</Modal.Content>
			<Modal.Actions>
				<SecondaryButton content="Cancel" onClick={onClose} />
				<Button color="red" content="Revert migration" loading={revertLoading} onClick={revertMigration} />
			</Modal.Actions>
		</>
	);
}

function Migrate({ production, setMessages, updateProductionStatusCallback, onClose, isFinalizing }) {
	const [organization] = useCurrentOrganization();
	const [convertToAnotherPublisher, setConvertToAnotherPublisher] = useState(false);
	const [doMigration, loading, error] = useLoadingFunction(async () => {
		const updatedApi = convertToAnotherPublisher ? { ...migrationApi, convertToAnotherPublisher } : migrationApi;
		const createdDocuments = await migrateProductionToFlattening(
			{ api: { ...updatedApi, isFinalizing }, env },
			{ production },
		);
		const messagesByCollection = reduceDocuments({ documents: createdDocuments });

		const messages = Object.entries(messagesByCollection).map(([collection, documents]) => {
			return {
				id: collection,
				header: `Created ${documents.length} ${collection}`,
				message: collection !== "artifact-files" && (
					<>
						{documents.map(({ id, linkText }, index) => (
							<React.Fragment key={id}>
								{linkText ? (
									<Link
										to={`/${
											organization.type === organizationTypes.ADMIN
												? "admin/"
												: `${organization.type}/${organization.id}/`
										}${collection}/${id}`}
									>
										{linkText}
									</Link>
								) : (
									id
								)}
								{index < documents.length - 1 && ", "}
							</React.Fragment>
						))}
					</>
				),
				created: true,
			};
		});

		setMessages(messages);
		updateProductionStatusCallback();
	});

	return (
		<>
			<Modal.Content>
				<p>{`Are you sure you wish to migrate ${production.title}?`}</p>
				<Checkbox
					label={"Convert to astrid publishing"}
					checked={convertToAnotherPublisher}
					onClick={() => {
						if (convertToAnotherPublisher) {
							setConvertToAnotherPublisher(false);
						} else {
							setConvertToAnotherPublisher(isLive ? "Ls9xocYGv4I37r7MVTEV" : "5wGBEakYMREKlmufsjLs");
						}
					}}
				/>
				{error && <ErrorMessage error={error} />}
			</Modal.Content>
			<Modal.Actions>
				<SecondaryButton content="Cancel" onClick={onClose} />
				<PrimaryButton content="Migrate" loading={loading} onClick={doMigration} />
			</Modal.Actions>
		</>
	);
}

export default function MigrateProduction({
	production,
	setMigrateProductionOpen,
	updateProductionStatusCallback = () => {},
	isFinalizing = false,
}) {
	const [messages, setMessages] = useState([]);
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	if (!hasFlatteningFeature) {
		return null;
	}

	const isMigrated = production.migrations?.flattening?.migrated;

	const onClose = () => {
		setMigrateProductionOpen(false);
	};

	return (
		<Modal open onClose={onClose} onKeyDown={stopPropagation} onClick={stopPropagation}>
			<Modal.Header content={isMigrated ? "Revert production" : "Migrate production"} />
			{messages?.length > 0 && (
				<Modal.Content>
					<MigrationMessages messages={messages} />
				</Modal.Content>
			)}
			{isMigrated ? (
				<RevertMigration production={production} setMessages={setMessages} onClose={onClose} />
			) : (
				<Migrate
					production={production}
					setMessages={setMessages}
					updateProductionStatusCallback={updateProductionStatusCallback}
					onClose={onClose}
					isFinalizing={isFinalizing}
				/>
			)}
		</Modal>
	);
}
