import { Button, Divider } from "semantic-ui-react";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import Form from "../../../forms/components/Form/Form";

import CalendarFiltersHideUnavailableCheckbox from "./components/CalendarFiltersHideUnavailableCheckbox";
import CalendarFiltersManagerSelect from "./components/CalendarFiltersManagerSelect";
import CalendarFiltersProductionSelect from "./components/CalendarFiltersProductionSelect";
import CalendarFiltersReaderSelect from "./components/CalendarFiltersReaderSelect";
import CalendarFiltersRecorderSelect from "./components/CalendarFiltersRecorderSelect";
import CalendarFiltersStudioSelect from "./components/CalendarFiltersStudioSelect";
import CalendarStatusFiltersDropdown from "./components/CalendarStatusFiltersDropdown";
import useCalendarStatusFilterOptions from "./hooks/useCalendarStatusFilterOptions";

export default function CalendarFilterForm({ form, organization }) {
	const { t } = useTranslation();

	const [openDropdown, setOpenDropdown] = useState(false);

	const statusFilterOptions = useCalendarStatusFilterOptions();

	return (
		<Form form={form}>
			<CalendarStatusFiltersDropdown
				onClick={() => setOpenDropdown((value) => !value)}
				openDropdown={openDropdown}
				onReset={form.onResetStatusFilters}
				onClear={form.onClearStatusFilters}
				options={statusFilterOptions}
				active={form.isStatusFiltersActive}
			/>

			<Divider hidden style={{ margin: 5 }} />

			<CalendarFiltersProductionSelect organizationId={organization.id} />

			<CalendarFiltersReaderSelect />

			<CalendarFiltersRecorderSelect organizationId={organization.id} />

			<CalendarFiltersHideUnavailableCheckbox organization={organization} form={form} />

			<CalendarFiltersManagerSelect organizationId={organization.id} />

			<CalendarFiltersStudioSelect organizationId={organization.id} />

			<Button.Group basic fluid size="tiny" style={{ marginTop: 10 }}>
				<Button size="tiny" onClick={form.resetFilters} disabled={!form.isFiltersActive}>
					{t("reset", "Reset")}
				</Button>

				<Button size="tiny" onClick={form.clearFilters} disabled={!form.activeFilters}>
					{t("clear", "Clear")}
				</Button>
			</Button.Group>
		</Form>
	);
}
