import { useMemo } from "react";

import decodeAudioBuffer from "astrid-helpers/src/audio/buffer/decodeAudioBuffer";
import concatArrayBuffers from "astrid-helpers/src/buffer/concatArrayBuffer";
import { fetchArrayBufferRange } from "astrid-helpers/src/buffer/fetchArrayBuffer";

const MS_PER_CHUNK = 1000 * 60 * 5;

export default function useArtifactAudioPlayerChunks({ files }) {
	return useMemo(() => {
		return files.flatMap((file) => {
			const { byteRate, headerSize } = file.format;

			const chunkByteSize = (byteRate * MS_PER_CHUNK) / 1000;
			const numberOfChunks = Math.ceil(file.length / MS_PER_CHUNK);

			const headerRange = [0, headerSize - 1];

			return Array.from({ length: numberOfChunks }, (_, i) => {
				const startByte = i * chunkByteSize;
				const endByte = (i + 1) * chunkByteSize - 1;
				const audioRange = [headerSize + startByte, headerSize + endByte];

				const start = file.start + i * MS_PER_CHUNK;
				const stop = Math.min(file.start + (i + 1) * MS_PER_CHUNK, file.stop);

				const getBuffer = () => {
					return Promise.all([
						fetchArrayBufferRange(file.url, headerRange),
						fetchArrayBufferRange(file.url, audioRange),
					])
						.then(concatArrayBuffers)
						.then(decodeAudioBuffer);
				};

				return {
					start,
					stop,
					getBuffer,
				};
			});
		});
	}, [files]);
}
