import firebase from "firebase/app";

import { Header } from "semantic-ui-react";

import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { db } from "astrid-firebase";
import artifactValidationStatuses from "astrid-firestore/src/api/artifacts/constants/artifactValidationStatuses";
import deliverArtifacts from "astrid-firestore/src/api/artifacts/deliverArtifacts";
import getArtifactsQuery from "astrid-firestore/src/api/artifacts/getArtifactsQuery";
import useCollectionData from "astrid-firestore/src/hooks/useCollectionData";
import useCollectionDataByIdsOnce from "astrid-firestore/src/hooks/useCollectionDataByIdsOnce";
import useLoadingValues from "astrid-firestore/src/hooks/useLoadingValues";

import Flex from "../../../../components/Flex/Flex";
import AsyncButton from "../../../ui/components/AsyncButton/AsyncButton";
import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import AddArticleButton from "../../../articles/components/AddArticleForm/AddArticleButton";
import ArtifactFilesUploadButton from "../../../artifacts/components/ArtifactFilesUpload/ArtifactFilesUploadButton";
import useHasRight from "../../../authorization/hooks/useHasRight";
import { getOrganizationPath } from "../../../routing/utils/getOrganizationPath";
import Table from "../../../tables/components/Table";

import ProductionViewContext from "../ProductionView/context/ProductionViewContext";

import useProductionArticlesTableColumns from "./hooks/useProductionArticlesTableColumns";
import useProductionArtifactsTableColumns from "./hooks/useProductionArtifactsTableColumns";

export default function ProductionArticlesView({ production }) {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const hasRight = useHasRight();

	const { producer, publisher } = useContext(ProductionViewContext);

	const [[articles, artifacts], loading, error] = useLoadingValues(
		useCollectionDataByIdsOnce(firebase, db.collection("articles"), production.articleIds),
		useCollectionData(getArtifactsQuery(firebase, { production })),
	);

	const articleColumns = useProductionArticlesTableColumns({ production });
	const artifactColumns = useProductionArtifactsTableColumns({ production });

	return (
		<LoadingContext loading={loading} error={error}>
			<Header as="h4" content={t("articles", "Articles")} />
			<Table
				data={articles}
				columns={articleColumns}
				onClick={({ id }) => navigate(getOrganizationPath(`/articles/${id}`))}
			/>
			<AddArticleButton publisher={publisher} production={production} />

			{hasRight("productions.files") && (
				<>
					<Header as="h4" content={t("files", "Files")} />
					<Table
						data={artifacts}
						columns={artifactColumns}
						onClick={({ id }) => navigate(`../artifacts/${id}`)}
						enableCheckboxes={(artifact) => artifact.status === artifactValidationStatuses.VALID}
					>
						{({ selectedRows }) => (
							<Flex gap={10} justifyContent="flex-start">
								<ArtifactFilesUploadButton producer={producer} production={production} />
								<AsyncButton
									positive
									disabled={selectedRows.length === 0}
									onClick={() =>
										deliverArtifacts(firebase, {
											production,
											articles,
											artifacts: selectedRows,
										})
									}
								>
									{t("deliverFiles", "Deliver files")}
								</AsyncButton>
							</Flex>
						)}
					</Table>
				</>
			)}
		</LoadingContext>
	);
}
