import { Outlet, useOutletContext } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import SuperAdminSettingsView from "../views/SuperAdminSettingsView/SuperAdminSettingsView";

export default function SuperAdminSettings() {
	const hasRight = useHasRight();
	const context = useOutletContext();

	if (!hasRight("distributions.admin")) {
		return <Unauthorized />;
	}

	return (
		<SuperAdminSettingsView context={context}>
			<Outlet context={context} />
		</SuperAdminSettingsView>
	);
}
