const actionNames = {
	ADD_AUDIO_TAGS: "addAudioTags",
	CONCAT_AUDIO: "concatAudio",
	COMPRESS_IMAGE: "compressImage",
	GENERATE_METADATA: "generateMetadata",
	GET_COVER: "getCover",
	GET_ARTIFACT_FILES: "getArtifactFiles",
	GET_METADATA: "getMetadata",
	HTML_TO_EXCEL: "htmlToExcel",
	RECODE_AUDIO: "recodeAudio",
	RENAME_FILE: "renameFile",
	RESIZE_IMAGE: "resizeImage",
	SAVE_TO_BUCKET: "saveToBucket",
	SEND_TO_FTP: "sendToFTP",
	SEND_TO_SFTP: "sendToSFTP",
	SSH2_SFTP: "ssh2SFTP",
	SEND_TO_API: "sendToAPI",
	SEND_TO_S3: "sendToS3",
	SPLIT_AUDIO: "splitAudio",
	ZIP_FILES: "zipFiles",
};

export default actionNames;
