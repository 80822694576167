import { Header } from "semantic-ui-react";

import React from "react";

import Flex from "../../../../../components/Flex/Flex";
import CoverImage from "../../../../ui/components/CoverImage/CoverImage";
import truncateString from "../../../../ui/utils/truncateString";

export default function QuickSearchItemProduction({ item, showProducer, showPublisher }) {
	return (
		<Flex justifyContent="flex-start">
			<div style={{ marginRight: "1em", height: 40, width: 40 }}>
				<CoverImage
					thumb
					rounded
					data={{ coverThumb: { url: item.imgThumb } }}
					size={40}
					style={{ margin: "0 auto" }}
				/>
			</div>

			<Header as="h5" style={{ margin: 0 }}>
				{truncateString(item.title, 50)}
				<Header.Subheader>
					{showProducer && item.producerName}
					{showProducer && showPublisher && " - "}
					{showPublisher && item.publisherName}
				</Header.Subheader>
			</Header>
		</Flex>
	);
}
