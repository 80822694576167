import firebase from "firebase/app";

import { Button, FormField, Segment } from "semantic-ui-react";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
	defaultProductionCategory,
	productionCategories,
} from "astrid-config/src/productionCategories/productionCategories";
import { db } from "astrid-firebase";
import productionStatuses from "astrid-firestore/src/api/productions/constants/productionStatuses";
import createProduction from "astrid-firestore/src/api/productions/createProduction";
import { Production } from "astrid-firestore/src/api/productions/types/Production";
import { useDocumentDataOnce } from "astrid-firestore/src/hooks";
import useLoadingValues from "astrid-firestore/src/hooks/useLoadingValues";

import Flex from "../../../../components/Flex/Flex";
import ErrorMessage from "../../../ui/components/Messages/ErrorMessage";

import useArticleTypeTranslations from "../../../articles/hooks/useArticleTypeTranslations";
import { useProfile } from "../../../authentication/state/profile";
import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";
import usePublisherManagerOptions from "../../../users/hooks/usePublisherManagerOptions";

export default function CreateProductionProducerForm({
	defaultWorkId,
	defaultArticleId,
	defaultProducerId = null,
	defaultPublisherId,
	setProducerId,
	setPublisherId,
	producerOptions,
	publisherOptions,
	enableProductionCategory,
}) {
	const user = useProfile();

	const navigate = useNavigate();

	const { t } = useTranslation(["common", "language"]);

	const articleTypeTranslations = useArticleTypeTranslations();

	const { form, watch, formState, error, onSubmit } = useSimpleForm({
		schema: Production,
		values: {
			status: productionStatuses.OFFER,
			workId: defaultWorkId || null,
			articleIds: defaultArticleId ? [defaultArticleId] : [],
			producerId: defaultProducerId,
			publisherId: defaultPublisherId,
			publisherManagers: [],
			title: "",
			language: "",
			category: enableProductionCategory ? defaultProductionCategory : null,
			createdBy: user.email,
		},
		onSubmit: (data) => {
			return createProduction(firebase, data);
		},
		onSuccess: ({ id }) => {
			navigate(`/production/${id}`);
		},
	});

	const { publisherId, producerId } = watch();

	const [[publisher, publisherManagerOptions], loading] = useLoadingValues(
		useDocumentDataOnce(publisherId && db.collection("organizations").doc(publisherId)),
		usePublisherManagerOptions(publisherId),
	);

	useEffect(() => {
		setProducerId?.(producerId);
	}, [producerId, setProducerId]);

	useEffect(() => {
		setPublisherId?.(publisherId);
	}, [publisherId, setPublisherId]);

	return (
		<>
			<Segment padded>
				<Form form={form}>
					<Form.Select
						name="producerId"
						disabled={producerOptions?.length <= 1}
						label={t("productionCompany", "Production company")}
						search
						deburr
						options={producerOptions}
					/>

					<Form.Select
						name="publisherId"
						disabled={publisherOptions?.length <= 1}
						label={t("publisher", "Publisher")}
						search
						deburr
						options={publisherOptions}
					/>

					<Form.DocumentSelect
						name="publisherManagers"
						label={t("publisherManager", "Publisher manager")}
						disabled={!publisherId}
						multiple
						search
						deburr
						options={publisherManagerOptions}
						loading={loading}
					/>

					<Form.Input name="title" label={t("title", "Title")} disabled={!publisherId} />

					<Form.LanguageSelect
						name="language"
						label={t("language", "Language")}
						disabled={!publisherId}
						limitedOptions={publisher?.languageOptions}
						deburr
					/>

					{enableProductionCategory && (
						<FormField>
							<label>{t("productionCategory", "Production category")}</label>

							<Flex style={{ paddingTop: 5 }}>
								{productionCategories.map((category) => (
									<div key={category}>
										<Form.Radio
											name="category"
											label={category}
											disabled={!producerId}
											value={category}
										/>
									</div>
								))}
							</Flex>
						</FormField>
					)}
				</Form>
			</Segment>

			{error && <ErrorMessage error={error} />}

			<Flex justifyContent="center">
				<Button
					loading={formState.isSubmitting}
					disabled={formState.isSubmitting}
					style={{ marginTop: 15, width: 200 }}
					primary
					content={t("create")}
					onClick={onSubmit}
				/>
			</Flex>
		</>
	);
}
