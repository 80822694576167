import trim from "lodash/trim";

import createDocumentData from "../../utils/createDocumentData";
import getDocumentData from "../../utils/getDocumentData";

import { Production } from "./types/Production";

export default async function createProduction(firebase, data) {
	const db = firebase.firestore();

	if (data.title) {
		data.title = trim(data.title);
	}

	if (data.publisherId) {
		const publisher = await getDocumentData(db.collection("organizations").doc(data.publisherId));

		data.publisher = publisher.id;
		data.publisherName = publisher.name;
	}

	if (data.producerId) {
		const producer = await getDocumentData(db.collection("organizations").doc(data.producerId));

		data.producer = producer.id;
		data.producerName = producer.name;
	}

	if (data.workId) {
		data.work = await getDocumentData(db.collection("works").doc(data.workId));
	}

	if (data.publisherManagers) {
		data.publisherManagerIds = data.publisherManagers.map(({ id }) => id);
	}

	const production = Production.parse(createDocumentData(firebase, "productions", data));

	await production.ref.set(production);

	return production;
}
