import { useTranslation } from "react-i18next";

import artifactProductionStatuses from "astrid-firestore/src/api/artifacts/constants/artifactProductionStatuses";

export default function useArtifactProductionStatuses() {
	const { t } = useTranslation();

	return {
		[artifactProductionStatuses.NOT_DELIVERED]: {
			text: t("notDelivered", "Not delivered"),
			color: "blue",
		},
		[artifactProductionStatuses.DELIVERED]: {
			text: t("delivered", "Delivered"),
			color: "green",
		},
		[artifactProductionStatuses.ARCHIVED]: {
			text: t("archived", "Archived"),
			color: "grey",
		},
	};
}
