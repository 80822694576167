import React from "react";
import { useTranslation } from "react-i18next";

import ArticleItem from "../../../../articles/components/ArticleItem/ArticleItem";
import ArticleType from "../../../../articles/components/ArticleType/ArticleType";
import { timestampColumn } from "../../../../ui/utils/columns";

export default function useProductionArticlesTableColumns() {
	const { t } = useTranslation();

	return [
		{
			id: "name",
			Header: t("title", "Title"),
			Cell: ({ row }) => <ArticleItem article={row} />,
		},
		{
			id: "type",
			Header: t("type", "Type"),
			Cell: ({ row }) => <ArticleType article={row} />,
		},
		{
			id: "isbn",
			Header: t("isbn", "ISBN"),
		},
		timestampColumn({ id: "deliveryDate", Header: t("deliveryDate", "Delivery date"), time: false }),
	];
}
