export default function concatArrayBuffers(buffers) {
	const totalLength = buffers.reduce((acc, buffer) => acc + buffer.byteLength, 0);
	const combined = new Uint8Array(totalLength);

	let offset = 0;

	for (const buffer of buffers) {
		combined.set(new Uint8Array(buffer), offset);

		offset += buffer.byteLength;
	}

	return combined.buffer;
}
