import createDocumentData from "../../utils/createDocumentData";
import runTransaction from "../../utils/runTransaction";

import updateArticle from "../articles/updateArticle";

import createArtifactFiles from "./createArtifactFiles";
import { Artifact } from "./types/Artifact";

export default function createArtifact(firebase, data, files) {
	return runTransaction(firebase, [data.article?.ref], async (transaction, [article]) => {
		const size = files.reduce((sum, file) => sum + (file.size || 0), 0);
		const duration = files.reduce((acc, file) => acc + (file.duration || 0), 0);

		const artifact = Artifact.parse(
			createDocumentData(firebase, "artifacts", {
				...data,
				files: files.map(({ id }) => id),
				size,
				duration,
			}),
		);

		if (data.article) {
			await updateArticle(firebase, { ref: article.ref, artifact, duration }, { transaction });
		}

		await createArtifactFiles(firebase, artifact, files, transaction);

		transaction.set(artifact.ref, artifact);
	});
}
