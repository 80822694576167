import createDocumentData from "../../utils/createDocumentData";
import runTransaction from "../../utils/runTransaction";

import { Imprint } from "../imprints/types/Imprint";
import { Serie } from "../series/types/Serie";

import getArticleDeliveryNeededData from "./data/getArticleDeliveryNeededData";
import { Article } from "./types/Article";
import { ArticleVersion } from "./types/ArticleVersion";
import validateArticleISBN from "./validateArticleISBN";

export default async function updateArticle(firebase, data, { transaction, saveVersion = false } = {}) {
	if (data.isbn) {
		await validateArticleISBN(data);
	}

	return runTransaction(
		firebase,
		[data.ref, data.serie?.ref, data.imprint?.ref, data.artifact?.ref],
		(transaction, [article, serie, imprint, artifact]) => {
			// Update the duration for default artifact
			if (artifact && !data.duration) {
				data.duration = artifact.duration;
			}

			// If the imprint does not exist, create it
			if (data.imprint && !imprint?.exists) {
				transaction.set(imprint.ref, Imprint.parse({ ...data.imprint, publisher: article.publisher }));
			}

			// Add the article to a production
			if (data.production) {
				transaction.update(data.production.ref, {
					articleIds: firebase.firestore.FieldValue.arrayUnion(article.id),
				});
			}

			// Update serie data if serie is changed or season is changed
			if (data.serie && (data.serie.id !== article.serie?.id || data.season?.id !== article.season?.id)) {
				const articleIds = firebase.firestore.FieldValue.arrayUnion(article.id);
				const seasons = serie.seasons?.find((s) => s.id === data.season?.id)
					? serie.seasons
					: [...(serie.seasons || []), data.season].filter(Boolean);

				if (serie?.exists) {
					transaction.update(serie.ref, {
						...Serie.partial().parse({ seasons }),
						articleIds,
					});
				} else {
					transaction.set(serie.ref, {
						...Serie.parse({ ...data.serie, seasons, publisher: article.publisher }),
						articleIds,
					});
				}
			}

			// Update serie on article. Remove article from previous selected serie
			if (article?.serie?.id && article.serie?.id !== serie?.id) {
				transaction.update(article.serie.ref, {
					articleIds: firebase.firestore.FieldValue.arrayRemove(article.id),
				});
			}

			// Save the updated article in the version history
			if (saveVersion) {
				const version = ArticleVersion.parse(
					createDocumentData(firebase, `articles/${article.id}/versions`, {
						before: article,
						after: {
							...article,
							...data,
						},
					}),
				);

				transaction.set(version.ref, version);
			}

			// Updated delivery needed status if metadata has changed
			const deliveryNeededData = getArticleDeliveryNeededData(article, data, !!data.artifact);
			if (deliveryNeededData) {
				transaction.update(article.ref, deliveryNeededData);
			}

			const validatedArticle = Article.partial().parse(data);

			transaction.set(article.ref, validatedArticle, { merge: true });

			return validatedArticle;
		},
		transaction,
	);
}
