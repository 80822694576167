import firebase from "firebase";

import { useTranslation } from "react-i18next";

import softDeleteDocument from "astrid-firestore/src/api/documents/softDeleteDocument";
import updateWork from "astrid-firestore/src/api/works/updateWork";

import { useProfile } from "../../../../authentication/state/profile";
import useFileUpload from "../../../../ui/hooks/useFileUpload";

export default function useWorkDropdownMenuOptions({ work }) {
	const user = useProfile();

	const { t } = useTranslation();

	const [{ uploadFile, input, error, loading }] = useFileUpload({
		bucketName: "earselect-uploads",
		getDestination: () => `covers/${work.id}-${Date.now()}`,
		onSuccess: (cover) => {
			return updateWork(firebase, { ref: work.ref, cover });
		},
	});

	const options = [
		{
			text: t("uploadCover", "Upload cover"),
			onClick: uploadFile,
		},
		{
			text: t("delete", "Delete"),
			confirmText: t("deleteWorkConfirmation", "Are you sure you want to delete this work?"),
			action: () => {
				return softDeleteDocument(firebase, work, user);
			},
		},
	];

	return { options, input, loading, error };
}
