import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import db from "astrid-firebase/src/db";
import useCollectionData from "astrid-firestore/src/hooks/useCollectionData";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import AdminCard from "../../../admin/components/AdminCard/AdminCard";
import ArtifactFilesUploadButton from "../../../artifacts/components/ArtifactFilesUpload/ArtifactFilesUploadButton";
import useArtifactsTableColumns from "../../../artifacts/hooks/useArtifactsTableColumns";
import Table from "../../../tables/components/Table";

export default function ArticleArtifactsView({ article, organization }) {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const [artifacts, loading, error] = useCollectionData(
		db
			.collection("artifacts")
			.where("article.id", "==", article.id)
			.where("deleted", "==", false)
			.orderBy("created", "desc"),
		// article.work
		// 	? db.collection("artifacts").where("work.id", "==", article.work.id)
		// 	: db.collection("artifacts").where("article.id", "==", article.id),
	);

	const columns = useArtifactsTableColumns({ article });

	return (
		<AdminCard transparent header={t("files", "Files")}>
			<LoadingContext data={artifacts} loading={loading} error={error}>
				<Table data={artifacts} columns={columns} onClick={({ id }) => navigate(id)} />
			</LoadingContext>

			<ArtifactFilesUploadButton article={article} publisher={organization} defaultName={article.name} />
		</AdminCard>
	);
}
