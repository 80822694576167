import { Accordion, Message, Modal } from "semantic-ui-react";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import db from "astrid-firebase/src/db";
import { canGrabSession } from "astrid-firestore/src/api/sessions/grabSession";
import { useDocumentData } from "astrid-firestore/src/hooks";

import Button from "../../../ui/components/Buttons/Button";
import PrimaryButton from "../../../ui/components/Buttons/PrimaryButton";
import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import { useProfile } from "../../../authentication/state/profile";
import useHasRight from "../../../authorization/hooks/useHasRight";

import CalendarCancelSessionForm from "./components/CalendarCancelSessionForm";
import CalendarEditSession from "./components/CalendarEditSession";
import CalendarGrabSessionButton from "./components/CalendarGrabSessionButton";
import CalendarProductionInformation from "./components/CalendarProductionInformation";
import CalendarSessionInformation from "./components/CalendarSessionInformation";

export default function CalendarSessionModal({ session, onClose, sessions }) {
	const { t } = useTranslation();
	const user = useProfile();
	const hasRight = useHasRight();

	const [isCancelling, setIsCancelling] = useState(false);
	const [error, setError] = useState(null);

	const isSessionRecorder = user.id === session.recorder;
	const isSessionReader = session.reader.includes(user.id);
	const isSessionManager = session.managerIds?.includes(user.id);

	const today = new Date();
	today.setHours(0, 0, 0, 0);

	const hasSessionEnded = session.end.toDate() < today;

	const canEditSessions =
		hasRight("calendar.editAllSessions") ||
		(hasRight("calendar.editOwnSessions") && session.managerIds?.includes(user.id));

	const [production, loadingProduction, errorProduction] = useDocumentData(
		session?.production && db.collection("productions").doc(session.production),
	);

	const onClickBack = () => {
		setIsCancelling(false);
	};

	return (
		<Modal size="small" open closeOnDimmerClick onClose={onClose}>
			<LoadingContext loading={loadingProduction} error={errorProduction}>
				<Modal.Header>{`${t("recSession", "Session")} - ${production?.title}`}</Modal.Header>

				{!isCancelling ? (
					<>
						<Modal.Content>
							<Accordion fluid>
								<CalendarSessionInformation session={session} production={production} />

								{canEditSessions && !hasSessionEnded && (
									<CalendarEditSession
										session={session}
										production={production}
										sessions={sessions}
									/>
								)}
								<CalendarProductionInformation session={session} production={production} />
							</Accordion>
							{production?.deleted && (
								<Message negative>{t("prodIsDeleted", "This production is deleted")}</Message>
							)}

							{error && <Message negative>{t("errorMessageHeader", "Something went wrong")}</Message>}
						</Modal.Content>

						<Modal.Actions>
							<PrimaryButton color={undefined} onClick={onClose}>
								{t("close")}
							</PrimaryButton>

							{canEditSessions && !hasSessionEnded && (
								<PrimaryButton color="red" onClick={() => setIsCancelling(true)}>
									{t("cancelSession", "Cancel session")}
								</PrimaryButton>
							)}

							{(canEditSessions || isSessionRecorder || isSessionManager || isSessionReader) &&
								!production?.deleted && (
									<Button as={Link} to={"/production/" + session.production}>
										{t("toProduction", "To production")}
									</Button>
								)}

							{!production?.deleted &&
								hasRight("studioSession.connect") &&
								canGrabSession({ session, user, production }) && (
									<CalendarGrabSessionButton
										session={session}
										production={production}
										setError={setError}
									/>
								)}
						</Modal.Actions>
					</>
				) : (
					<CalendarCancelSessionForm onClickBack={onClickBack} onClose={onClose} session={session} />
				)}
			</LoadingContext>
		</Modal>
	);
}
