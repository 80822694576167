import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import db from "astrid-firebase/src/db";
import useCollectionData from "astrid-firestore/src/hooks/useCollectionData";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";
import useSearch from "../../../ui/hooks/useSearch";
import useSearchInput from "../../../ui/hooks/useSearchInput";
import PageView from "../../../ui/views/PageView/PageView";

import Table from "../../../tables/components/Table";

import CreateStudioButton from "../../components/CreateStudio/CreateStudioButton";

import useStudiosTableColumns from "./hooks/useStudiosTableColumns";

export default function StudiosView({ organization }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const columns = useStudiosTableColumns();

	const [data, loading, error] = useCollectionData(
		db.collection("studios").where("producerId", "==", organization.id).orderBy("name"),
	);

	const [searchInput, searchQuery] = useSearchInput();

	const filteredData = useSearch(data, searchQuery, ["name"]);

	return (
		<PageView
			header={t("studios", "Studios")}
			extra={<CreateStudioButton organization={organization} />}
			sidebar={searchInput}
		>
			<LoadingContext data={filteredData} loading={loading} error={error}>
				<Table data={filteredData} columns={columns} onClick={({ id }) => navigate(id)} />
			</LoadingContext>
		</PageView>
	);
}
