import { useMemo, useRef } from "react";

import useDebounce from "astrid-hooks/src/useDebounce";

export default function useSearch(data, query = "", keys = [], { debounce = 100 } = {}) {
	const keysRef = useRef(keys);
	const debouncedSearchQuery = useDebounce(query, debounce);

	return useMemo(() => {
		const lowerCaseQuery = debouncedSearchQuery.toLowerCase();

		return data?.filter((studio) =>
			debouncedSearchQuery
				? keysRef.current.some((key) => studio[key].toLowerCase().includes(lowerCaseQuery))
				: true,
		);
	}, [data, debouncedSearchQuery]);
}
