import { useDebounce } from "use-debounce";

import React, { memo, useCallback, useMemo } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";
import useAsync from "astrid-hooks/src/useAsync";
import useAudioWave from "astrid-studio-web/src/hooks/useAudioWave";

const Svg = memo(({ html }) => <div dangerouslySetInnerHTML={{ __html: html }} />);

export default function ArtifactAudioPlayerTimelineChunk({ chunk }) {
	const [scope] = useDebounce(Timeline.useScope(), 300);

	const buffer = useAsync(useCallback(() => chunk.getBuffer(), [chunk]));

	const options = useMemo(
		() => ({
			end: Math.min(chunk.stop - chunk.start, scope.end - chunk.start),
			start: Math.max(0, scope.start - chunk.start),
		}),
		[chunk.start, chunk.stop, scope.end, scope.start],
	);

	const wave = useAudioWave(buffer, options);

	return (
		<Timeline.Part start={chunk.start} stop={chunk.stop} loading={!wave}>
			{wave && (
				<Timeline.Wave start={wave.start} end={wave.end}>
					{wave.shapes.map((shape, index) => (
						<Svg key={index} {...shape} />
					))}
				</Timeline.Wave>
			)}
		</Timeline.Part>
	);
}
