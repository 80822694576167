import Cache from "lru-cache";

const cache = new Cache({ max: 50 });

export function fetchArrayBuffer(url, headers) {
	if (url) {
		const key = JSON.stringify({ url, headers });

		if (!cache.has(key)) {
			const promise = fetch(url, { headers })
				.then((res) => res.arrayBuffer())
				.catch(() => {
					if (cache.get(key) === promise) {
						cache.delete(key);
					}
				});

			cache.set(key, promise);
		}

		return cache.get(key);
	}
}

export function fetchArrayBufferRange(url, bytes) {
	return fetchArrayBuffer(url, {
		Range: `bytes=${bytes[0]}-${bytes[1]}`,
	});
}
