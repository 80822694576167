import { Outlet, useOutletContext, useParams } from "react-router-dom";

import NotFound from "../../../../components/NotFound";
import useHistory from "../../../ui/hooks/useHistory";

import { useProfile } from "../../../authentication/state/profile";
import { useUser } from "../../../authentication/state/user";

import ProductionView from "../../views/ProductionView/ProductionView";

export default function Production() {
	const history = useHistory();
	const { modal } = useParams();
	const { production } = useOutletContext();

	const user = useUser();
	const profile = useProfile();

	if (!production || production?.deleted) {
		return <NotFound />;
	}

	return (
		<ProductionView modal={modal} production={production} history={history}>
			<Outlet context={{ user, profile, history, production }} />
		</ProductionView>
	);
}
