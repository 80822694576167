import mapDocument from "../utils/mapDocument";

import getCollection from "./getCollection";

export default async function getCollectionData(ref) {
	if (!ref) {
		return [];
	}

	const documents = await getCollection(ref);

	return documents.map(mapDocument);
}
