import uniqid from "uniqid";

import { useMemo } from "react";

import prepareArtifactMarkers from "astrid-firestore/src/api/artifacts/prepareArtifactMarkers";

import Form from "../../../../forms/components/Form/Form";

export default function useArtifactManagerMarkers({ form, artifact, files }) {
	const { append, update, remove } = Form.useFieldArray({ name: "markers", control: form.control });

	const [markers, fileIds] = form.watch(["markers", "fileIds"]);

	const onAddMarker = ({ type, position }) => {
		const file = files.find((file) => file.start <= position && file.stop >= position);

		append({
			type,
			id: uniqid(),
			text: "",
			fileId: file.id,
			position: position - file.start,
		});
	};

	const onUpdateMarker = ({ marker, position }) => {
		const file = files.find((file) => file.start <= position && file.stop >= position);

		update(marker.index, {
			...marker,
			fileId: file.id,
			position: position - file.start,
		});
	};

	const onRemoveMarker = ({ marker }) => {
		remove(marker.index);
	};

	const preparedMarkers = useMemo(
		() =>
			prepareArtifactMarkers({
				artifact: {
					...artifact,
					markers,
					files: fileIds,
				},
				files,
			}),
		[artifact, fileIds, files, markers],
	);

	return {
		markers: preparedMarkers,
		onAddMarker,
		onUpdateMarker,
		onRemoveMarker,
	};
}
