import { Divider, Header } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import CostBalance from "astrid-web/src/features/costs/components/CostBalance/CostBalance";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";
import SecondaryMenu from "../../../ui/components/SecondaryMenu/SecondaryMenu";

import FinalizedCostsTable from "../../../costs/components/FinalizedCostsTable/FinalizedCostsTable";
import OpenCostsTable from "../../../costs/components/OpenCostsTable/OpenCostsTable";
import OpenCostsActions from "../../../costs/components/OpenCostsTable/components/OpenCostsActions";

import ProductionAgreements from "../../components/ProductionAgreements/ProductionAgreements";

import useProductionFinance from "./hooks/useProductionFinance";

export default function ProductionFinanceView({ production, partyId }) {
	const { t } = useTranslation();

	const [
		{ costs, openCosts, menuOptions, isPublisher, exchangeRate, selectedPartyId, finalizableOpenCosts },
		loading,
		error,
	] = useProductionFinance({
		partyId,
		production,
	});

	return (
		<LoadingContext loading={loading} error={error}>
			<SecondaryMenu options={menuOptions} />

			<Divider />

			{!isPublisher && <CostBalance costs={costs} openCosts={openCosts} />}

			<Header as="h4" content={t("openCosts", "Open")} />

			<OpenCostsTable
				partyId={selectedPartyId}
				openCosts={openCosts}
				production={production}
				exchangeRate={exchangeRate}
				footer={
					<OpenCostsActions
						partyId={selectedPartyId}
						production={production}
						openCosts={finalizableOpenCosts}
					/>
				}
			/>

			<Header as="h4" content={t("finalizedCosts", "Finalized")} />
			<FinalizedCostsTable costs={costs} reversed={isPublisher} />

			<Header as="h4" content={t("productionAgreement", "Production Agreement")} />
			<ProductionAgreements production={production} />
		</LoadingContext>
	);
}
