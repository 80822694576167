import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import Timeline from "astrid-components/lib/components/Audio/Timeline";
import artifactMarkerTypes from "astrid-firestore/src/api/artifacts/constants/artifactMarkerTypes";
import useTemp from "astrid-hooks/src/useTemp";

export default function ArtifactAudioPlayerTimelineMarker({ marker, onChange }) {
	const { t } = useTranslation();

	const [position, setPosition] = useTemp(marker.position);

	// const onClick = useCallback(() => {
	// 	selectMarker(marker.id, true);
	// 	jumpToMarker(marker.id);
	// }, [marker.id]);

	const onMove = useCallback(
		(position) => {
			setPosition(position);
		},
		[setPosition],
	);
	return (
		<Timeline.Marker
			active
			color={marker.safe ? marker.color : "negative"}
			position={position}
			onMove={marker.type === artifactMarkerTypes.CHAPTER ? onMove : undefined}
			// onClick={onClick}
			onChange={() => onChange(position)}
		>
			{marker.text} {!marker.safe && `(${t("unsafe", "Unsafe")})`}
		</Timeline.Marker>
	);
}
