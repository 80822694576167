import { DropdownHeader } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import Flex from "../../../../../components/Flex/Flex";
import renderChildren from "../../../../../utils/renderChildren";
import DropdownMenuItem from "../../../../ui/components/DropdownMenu/DropdownMenuItem";
import InstantSearchHits from "../../../../ui/components/InstantSearch/InstantSearchHits";

export default function QuickSearchItems({ header, getItemPath, getMorePath, children }) {
	const navigate = useNavigate();

	const { t } = useTranslation();

	return (
		<InstantSearchHits>
			{({ data, results }) => (
				<>
					{data.length > 0 && header && (
						<DropdownHeader
							style={{
								margin: 0,
								padding: "1rem",
								position: "relative",
								boxShadow: "0 -1px 0 rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05)",
								backgroundColor: "white",
							}}
						>
							<Flex>
								({results.nbHits}) {header}
								{getMorePath && <Link to={getMorePath()}>{t("showMore", "Show more")}</Link>}
							</Flex>
						</DropdownHeader>
					)}

					{data.map((item) => (
						<DropdownMenuItem key={item.id} onClick={() => navigate(getItemPath(item))}>
							{renderChildren(children, { item })}
						</DropdownMenuItem>
					))}
				</>
			)}
		</InstantSearchHits>
	);
}
