import FirestoreDocument from "../../../types/FirestoreDocument";
import FirestoreLifecycle from "../../../types/FirestoreLifecycle";
import { StorageFile } from "../../../types/StorageFile";
import { array, enumArray, nullable, number, object } from "../../../types/_types";

export const artifactFileTypes = {
	"audio/wav": [".wav"],
	"application/epub+zip": [".pub"],
};

export const ArtifactFile = StorageFile.extend({
	contentType: enumArray(Object.keys(artifactFileTypes)),
	duration: number().default(0),
	format: nullable(object().passthrough()),
	silences: nullable(
		array(
			object({
				start: number(),
				end: number(),
			}),
		),
	).default(null),
})
	.merge(FirestoreDocument)
	.merge(FirestoreLifecycle);
