import Label from "../../../ui/components/Label/Label";

import useArtifactValidationStatuses from "../../hooks/useArtifactValidationStatuses";

export default function ArtifactValidationStatusLabel({ artifact, ...props }) {
	const statuses = useArtifactValidationStatuses();

	const { text, color } = statuses[artifact.status];

	return (
		<Label color={color} {...props}>
			{text}
		</Label>
	);
}
