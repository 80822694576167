import { Segment } from "semantic-ui-react";

import React from "react";
import { useOutletContext } from "react-router-dom";

import ProductionArticlesView from "../../views/ProductionArticlesView/ProductionArticlesView";

import ProductionAudiobook from "./ProductionAudiobook";

export default function ProductionArticles() {
	const { production } = useOutletContext();

	// const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	if (!production?.work) {
		return (
			<Segment padded>
				<ProductionAudiobook tab="articles" />
			</Segment>
		);
	}

	// if (!hasFlatteningFeature) {
	// 	return (
	// 		<Segment basic padded>
	// 			<Unauthorized />
	// 		</Segment>
	// 	);
	// }

	return <ProductionArticlesView production={production} />;
}
