import firebase from "firebase";

import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import createArtifact from "astrid-firestore/src/api/artifacts/createArtifact";

import OpenButton from "../../../ui/components/OpenButton/OpenButton";

import { useProfile } from "../../../authentication/state/profile";

import ArtifactFilesUploadForm from "./ArtifactFilesUploadForm";

export default function ArtifactFilesUploadButton({ work, article, producer, publisher, production, ...props }) {
	const user = useProfile();

	const { t } = useTranslation();

	const { id } = useMemo(() => firebase.firestore().collection("artifacts").doc(), []);

	const onSuccess = (files) => {
		createArtifact(
			firebase,
			{
				id,
				name: id,
				createdBy: user,
				work: work || article?.work || null,
				article: article || null,
				producer: producer || null,
				publisher: publisher || null,
				production: production || null,
			},
			files,
		);
	};

	return (
		<OpenButton text={t("uploadFiles", "Upload files")} {...props}>
			{({ setOpen }) => (
				<ArtifactFilesUploadForm
					auto
					bucketName="earselect-artifacts"
					bucketPath={id}
					onSuccess={onSuccess}
					onClose={() => setOpen(false)}
				/>
			)}
		</OpenButton>
	);
}
