import { useTranslation } from "react-i18next";

import OpenButton from "../../../ui/components/OpenButton/OpenButton";

import CreateArticleForm from "./CreateArticleForm";

export default function CreateArticleButton({ publisher, onSuccess, ...props }) {
	const { t } = useTranslation();

	return (
		<OpenButton text={t("createArticle", "Create article")} primary {...props}>
			{({ setOpen }) => (
				<CreateArticleForm publisher={publisher} onSuccess={onSuccess} onClose={() => setOpen(false)} />
			)}
		</OpenButton>
	);
}
