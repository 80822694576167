import audioBitrates from "../constants/audioBitrates";
import audioChannels from "../constants/audioChannels";
import audioFormats from "../constants/audioFormats";

import actionNames from "./actionNames";
import pendingFileRenameFunctions from "./ftp/constants/pendingFileRenameFunctions";
import metadataTemplateTypes from "./processors/metadata/constants/metadataTemplateTypes";
import protocols from "./protocols";
import authTypes from "./sendToApi/authTypes";

const actionArgs = {
	[actionNames.GET_ARTIFACT_FILES]: [
		{
			id: "FORCE_AUDIO_CHANNEL_CONSISTENCY",
			type: "boolean",
		},
	],
	[actionNames.RECODE_AUDIO]: [
		{
			id: "AUDIO_FORMAT",
			required: true,
			options: Object.values(audioFormats),
		},
		{
			id: "AUDIO_BITRATE",
			type: "number",
			options: Object.values(audioBitrates),
		},
		{
			id: "AUDIO_CHANNELS",
			type: "number",
			options: Object.values(audioChannels),
		},
	],
	[actionNames.RENAME_FILE]: [
		{
			id: "FOLDER_PATTERN",
			type: "string",
		},
		{
			id: "FILE_PATTERN",
			type: "string",
		},
	],
	[actionNames.GENERATE_METADATA]: [
		{
			id: "METADATA_FORMAT",
			options: [metadataTemplateTypes.ONIX, metadataTemplateTypes.EXCEL, metadataTemplateTypes.CUSTOM_XML],
		},
	],
	[actionNames.SAVE_TO_BUCKET]: [
		{
			id: "BUCKET",
			type: "string",
		},
		{
			id: "PATH",
			type: "string",
		},
		{
			id: "SAVE_EXPORT_ZIP",
			type: "boolean",
		},
		{
			id: "CONCURRENT_UPLOADS",
			type: "number",
		},
	],
	[actionNames.SEND_TO_FTP]: [
		{
			id: "HOST",
			type: "string",
		},
		{
			id: "PORT",
			type: "string",
		},
		{
			id: "USER",
			type: "string",
		},
		{
			id: "PASSWORD",
			type: "password",
		},
		{
			id: "SECURE",
			type: "boolean",
		},
		{
			id: "CONCURRENT_UPLOADS",
			type: "number",
		},
	],
	[actionNames.SEND_TO_SFTP]: [
		{
			id: "HOST",
			type: "string",
		},
		{
			id: "PORT",
			type: "string",
		},
		{
			id: "USER",
			type: "string",
		},
		{
			id: "PASSWORD",
			type: "password",
		},
		{
			id: "PRIVATE_KEY",
			type: "string",
		},
		{
			id: "USE_PENDING_FILE_NAME",
			type: "boolean",
		},
		{
			id: "CONCURRENT_UPLOADS",
			type: "number",
		},
	],
	[actionNames.SSH2_SFTP]: [
		{
			id: "HOST",
			type: "string",
		},
		{
			id: "PORT",
			type: "string",
		},
		{
			id: "USER",
			type: "string",
		},
		{
			id: "PASSWORD",
			type: "password",
		},
		{
			id: "PRIVATE_KEY",
			type: "string",
		},
		{
			id: "PENDING_FILE_NAME_RENAME_FN",
			required: true,
			options: [
				pendingFileRenameFunctions.NONE,
				pendingFileRenameFunctions.RENAME,
				pendingFileRenameFunctions.POSIX_RENAME,
			],
		},
		{
			id: "CONCURRENT_UPLOADS",
			type: "number",
		},
	],
	[actionNames.SEND_TO_API]: [
		{
			id: "AUTH_TYPE",
			required: true,
			options: [authTypes.OAUTH, authTypes.NONE],
		},
		{
			id: "API_URL",
			type: "string",
		},
		{
			id: "AUTH_URL",
			type: "string",
		},
		{
			id: "USER",
			type: "string",
		},
		{
			id: "PASSWORD",
			type: "password",
		},
		{
			id: "EXTRA_AUTH_BODY_PARAMS",
			type: "string",
		},
		{
			id: "CONCURRENT_UPLOADS",
			type: "number",
		},
	],
	[actionNames.SEND_TO_S3]: [
		{
			id: "ACCESS_KEY_ID",
			type: "string",
		},
		{
			id: "SECRET_ACCESS_KEY",
			type: "password",
		},
		{
			id: "BUCKET_REGION",
			type: "string",
		},
		{
			id: "BUCKET_NAME",
			type: "string",
		},
		{
			id: "BUCKET_PATH",
			type: "string",
		},
		{
			id: "LANGUAGE_BASED_BUCKET_PATHS",
			type: "string",
		},
		{
			id: "CONCURRENT_UPLOADS",
			type: "number",
		},
	],
	[actionNames.RESIZE_IMAGE]: [
		{
			id: "MIN_WIDTH",
			type: "number",
		},
		{
			id: "MAX_WIDTH",
			type: "number",
		},
		{
			id: "MIN_HEIGHT",
			type: "number",
		},
		{
			id: "MAX_HEIGHT",
			type: "number",
		},
		{
			id: "SQUARE",
			type: "boolean",
		},
	],
	[actionNames.COMPRESS_IMAGE]: [
		{
			id: "MAX_SIZE_IN_BYTES",
			type: "number",
			required: true,
		},
	],
};

export default actionArgs;
