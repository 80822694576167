import { Header } from "semantic-ui-react";

import React from "react";

import contributorRoles from "astrid-firestore/src/api/articles/constants/contributorRoles";

import Flex from "../../../../../components/Flex/Flex";
import CoverImageStack from "../../../../ui/components/CoverImage/CoverImageStack";
import truncateString from "../../../../ui/utils/truncateString";

export default function QuickSearchItemWork({ item, showPublisher }) {
	const author = item.contributors?.find(({ role }) => role === contributorRoles.AUTHOR);

	return (
		<Flex justifyContent="flex-start">
			<div style={{ marginRight: "1em", height: 40, width: 40 }}>
				<CoverImageStack data={item} size={40} style={{ margin: "0 auto" }} />
			</div>

			<Header as="h5" style={{ margin: 0 }}>
				{truncateString(item.name, 50)} {item.episodeName && ` - ${item.episodeName}`}
				<Header.Subheader>
					{showPublisher && `${item.publisher.name} - `}
					{author && `${author.firstName} ${author.lastName}`}
				</Header.Subheader>
				<Header.Subheader></Header.Subheader>
			</Header>
		</Flex>
	);
}
