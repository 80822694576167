import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import useWindowSize from "react-use/lib/useWindowSize";

import { useProfile } from "../../../../authentication/state/profile";
import useHasRight from "../../../../authorization/hooks/useHasRight";

import tabHasNotification from "../utils/tabHasNotification";

export default function useProductionMenuOptions({ production, offers, alerts, discussionChannels }) {
	const { t } = useTranslation();
	const user = useProfile();
	const location = useLocation();
	const hasRight = useHasRight();

	const { width } = useWindowSize();
	const { pathname } = location;

	const isDesktop = width > 1000;

	const alertArray = Object.values(alerts);

	const isAdmin = hasRight("productions.admin");
	const isManager = hasRight("productions.manage");
	const isSubcontractor = hasRight("subcontractor", false);

	const infoAlerts = isManager ? alertArray?.filter((m) => m.tab === "info").length : 0;
	const articlesAlerts = isManager ? alertArray?.filter((m) => m.tab === "articles").length : 0;
	const audiobookAlerts = isManager ? alertArray?.filter((m) => m.tab === "audiobook").length : 0;

	const readerOffersNotifications = tabHasNotification("readers", { offers });
	const teamOffersNotifications = tabHasNotification("team", { offers });
	const discussionsNotifications = discussionChannels?.filter(
		({ seenByUserIds }) => !seenByUserIds.includes(user.id),
	).length;

	return [
		{
			to: `/production/${production.id}`,
			text: t("overview", "Overview"),
			label: infoAlerts || undefined,
			active: pathname.endsWith(production.id),
		},
		{
			to: "articles",
			text: t("articles", "Articles"),
			label: articlesAlerts || undefined,
			active: pathname.includes("articles"),
			hidden: !isManager || (!isAdmin && isSubcontractor),
		},
		{
			to: "audiobook",
			text: t("production", "Production"),
			label: audiobookAlerts || undefined,
			active: pathname.includes("audiobook"),
			hidden:
				!(typeof production.isbn === "string" || production.deliveryParts || production.work) ||
				// production.productionType || // Assigns string value that resolves false in check
				(typeof production.productionType === "undefined" && production.status === "draft") ||
				production.productionType === "external",
		},
		{
			to: "readers",
			text: t("narrators", "Narrators"),
			label: readerOffersNotifications || undefined,
			active: pathname.includes("readers"),
			hidden: !isManager || (!isAdmin && isSubcontractor) || production.productionType === "external",
		},
		{
			to: "team",
			text: t("teamAndOffers", "Team & Offers"),
			label: teamOffersNotifications || undefined,
			active: pathname.endsWith(`${production.id}/team`),
			hidden: !hasRight("productions.team") || production.status === "offer",
		},
		{
			to: "discussions",
			text: t("discussion", "Discussion"),
			label: discussionsNotifications || undefined,
			active: pathname.endsWith(`${production.id}/discussions`),
			hidden: isDesktop,
		},
		{
			to: "finance",
			text: t("finance", "Finance"),
			active: pathname.includes("finance"),
			hidden: !hasRight("finance.editProductionPrices") || production.status === "offer",
		},
		{
			to: "settings",
			text: t("settings", "Settings"),
			active: pathname.includes("settings"),
			hidden: !hasRight("productions.settings.level1"),
		},
	];
}
