import React from "react";
import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../../authorization/hooks/useHasRight";

import ProductionSettingsView from "../../views/ProductionSettingsView/ProductionSettingsView";

export default function ProductionSettings() {
	const { production } = useOutletContext();
	const hasRight = useHasRight();

	if (!hasRight("productions.settings.level1")) {
		return <Unauthorized />;
	}

	return <ProductionSettingsView production={production} />;
}
