import firebase from "firebase/app";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { db } from "astrid-firebase";
import articleStatuses from "astrid-firestore/src/api/articles/constants/articleStatuses";
import createArticle from "astrid-firestore/src/api/articles/createArticle";
import { Article } from "astrid-firestore/src/api/articles/types/Article";
import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import InstantSearchSelect from "../../../ui/components/InstantSearch/InstantSearchSelect";

import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

import useArticleTypeOptions from "../../hooks/useArticleTypeOptions";

export default function CreateArticleForm({ publisher: defaultPublisher, onClose }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const articleTypeOptions = useArticleTypeOptions();

	const { form, onSubmit, setValue, watch, error } = useSimpleForm({
		schema: Article.passthrough(),
		values: {
			name: "",
			isbn: null,
			type: null,
			work: null,
			status: articleStatuses.DRAFT,
			language: null,
			publisher: defaultPublisher || null,
		},
		onSubmit: (data) => {
			return createArticle(firebase, data);
		},
		onSuccess: ({ id }) => navigate(id),
	});

	const { work, publisher } = watch();

	useEffect(() => {
		setValue("name", work?.name || "");
	}, [setValue, work?.name]);

	useEffect(() => {
		setValue("language", work?.language || "");
	}, [setValue, work?.language]);

	return (
		<Form.Modal
			header={t("createArticle", "Create article")}
			size="tiny"
			form={form}
			error={error}
			onSubmit={onSubmit}
			onClose={onClose}
		>
			{!defaultPublisher && (
				<Form.CollectionSelect
					name="publisher"
					label={t("publisher", "Publisher")}
					query={db.collection("organizations").where("type", "==", organizationTypes.PUBLISHER)}
				/>
			)}

			<Form.Field name="work" label={t("work", "Work")}>
				<InstantSearchSelect
					indexName="works"
					filters={`publisher.id:${publisher?.id}`}
					getOption={(work) => ({
						key: work.id,
						text: work.name,
						value: work,
					})}
					allowEmpty
				/>
			</Form.Field>

			<Form.Input name="name" label={t("title", "Title")} />

			<Form.LanguageSelect
				name="language"
				label={t("language", "Language")}
				disabled={!!work}
				limitedOptions={publisher.languageOptions}
			/>

			<Form.Select name="type" label={t("type", "Type")} options={articleTypeOptions} />

			<Form.Input name="isbn" label={t("isbn", "ISBN")} />
		</Form.Modal>
	);
}
