import { useTranslation } from "react-i18next";

export default function useSuperAdminSettingsMenuOptions({ context }) {
	const { t } = useTranslation();

	return [
		{
			path: "/admin/settings/channels",
			name: t("channels", "Channels"),
		},
		{
			path: "/admin/settings/appendixes",
			name: t("appendixes", "Appendixes"),
		},
		{
			path: "/admin/settings/biblio",
			name: "Biblio",
			label: context.uncompletedBiblioTaskCount,
			labelColor: "red",
			options: [
				{
					path: "/admin/settings/biblio",
					name: t("settings", "Settings"),
				},
				{
					path: "/admin/settings/tasks",
					name: t("tasks", "Tasks"),
					label: context.uncompletedBiblioTaskCount,
					labelColor: "red",
				},
			],
		},
	];
}
