import { Checkbox } from "semantic-ui-react";

import React, { useContext } from "react";

import TableCell from "./TableCell";
import TableContext from "./TableContext";

export default function TableCellCheckbox({ row, enableCheckboxes }) {
	const { selectedRowIds, setSelectedRowIds } = useContext(TableContext);

	const enabled = typeof enableCheckboxes === "function" ? enableCheckboxes(row) : true;

	const onClick = () => {
		setSelectedRowIds((selectedRowIds) =>
			selectedRowIds.includes(row.id)
				? selectedRowIds.filter((id) => id !== row.id)
				: [...selectedRowIds, row.id],
		);
	};

	return (
		<TableCell first sticky collapsing onClick={enabled ? onClick : undefined}>
			<Checkbox checked={selectedRowIds.includes(row.id)} disabled={!enabled} />
		</TableCell>
	);
}
