export default function sortArtifactFiles(files, fileIds) {
	if (!files || !fileIds) {
		return [];
	}

	const filesObject = files.reduce(
		(acc, file) => ({
			...acc,
			[file.id]: file,
		}),
		{},
	);

	let offset = 0;

	return fileIds
		.filter((id) => filesObject[id])
		.map((id) => {
			const file = filesObject[id];

			const length = file.duration * 1000;

			const start = offset;
			const stop = start + length;

			offset = stop;

			return {
				...file,
				start,
				length,
				stop,
			};
		});
}
