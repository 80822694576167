import { useTranslation } from "react-i18next";

import secondsToDuration from "astrid-firestore/src/api/articles/utils/secondsToDuration";

import ArtifactStatusLabels from "../../../../artifacts/components/ArtifactStatus/ArtifactStatusLabels";
import { timestampColumn } from "../../../../ui/utils/columns";

export default function useProductionArtifactsTableColumns({ production }) {
	const { t } = useTranslation();

	return [
		{
			id: "status",
			Header: t("status", "Status"),
			collapsing: true,
			Cell: ({ row }) => {
				return <ArtifactStatusLabels artifact={row} production={production} />;
			},
		},
		{
			id: "name",
			Header: t("Name", "Name"),
			collapsing: true,
		},
		{
			id: "contentType",
			Header: t("contentType", "Content-Type"),
		},
		{
			id: "duration",
			Header: t("duration", "Duration"),
			accessor: ({ duration }) =>
				typeof duration === "number" ? secondsToDuration(duration, { padHours: 2 }) : undefined,
		},
		timestampColumn({ id: "created", Header: t("created", "Created") }),
	];
}
