import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Button from "../../../ui/components/Buttons/Button";
import CoverImageStack from "../../../ui/components/CoverImage/CoverImageStack";
import VerticalMenu from "../../../ui/components/VerticalMenu/VerticalMenu";
import useBreadcrumbs from "../../../ui/hooks/useBreadcrumbs";
import PageView from "../../../ui/views/PageView/PageView";

import AdminPageDropdownMenu from "../../../admin/components/AdminPage/AdminPageDropdownMenu";
import AddArticleButton from "../../../articles/components/AddArticleForm/AddArticleButton";

import useWorkDropdownMenuOptions from "./hooks/useWorkDropdownMenuOptions";
import useWorkMenuOptions from "./hooks/useWorkMenuOptions";

export default function WorkView({ work, children }) {
	const { t } = useTranslation();

	const breadcrumbs = useBreadcrumbs({ work });

	const options = useWorkMenuOptions({ work });

	const { input, loading, options: dropdownMenuOptions } = useWorkDropdownMenuOptions({ work });

	return (
		<PageView
			breadcrumbs={breadcrumbs}
			image={<CoverImageStack data={work} loading={loading} />}
			header={work.name}
			subheader={work.publisher.name}
			sidebar={<VerticalMenu options={options} />}
			extra={
				<>
					<AddArticleButton work={work} />

					<Button as={Link} to={`/publisher/${work.publisher.id}/create-production?work=${work?.id}`}>
						{t("createProduction", "Create production")}
					</Button>

					<AdminPageDropdownMenu options={dropdownMenuOptions} />
				</>
			}
		>
			{children}

			{input}
		</PageView>
	);
}
