import { StorageFile } from "../../src/types/StorageFile";

export default function createStorageFile({ file, metadata }) {
	const { id, name, fullPath, url, size, duration } = file;

	switch (metadata.contentType) {
		case "audio/wav":
		case "application/epub+zip":
			return {
				...metadata,
				id,
				name,
				fullPath,
				url,
				size,
				duration,
			};
		default:
			return StorageFile.parse({
				...metadata,
				fullPath,
				url,
				size,
			});
	}
}
